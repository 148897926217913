import React from 'react'
import {Link} from 'common/Links'
import {cn} from 'utils'

const Text = ({children}) => <p className="my-1">{children}</p>

function Alert({title, children, variant})
{
	return (
			<div className="vds-quicklinks-box my-0">
				<div className="vds-quicklinks-box-header">
					<h1 className={cn(variant && `text-${variant}`)}>{title}</h1>
				</div>
				<div className={cn('row', 'py-1', variant && `text-${variant}`, variant && `border-${variant}`)}>
					<div className="col">
						{children}
					</div>
				</div>
			</div>
	)
}

export default function DraftAvailableAlert({product}) {
	const {nextVersion} = product

	if (nextVersion?.state !== 'DRAFT') {
		return null
	}

	return (
		<div className="row">
			<div className="col">
				<Alert title="Entwurf verfügbar">
					<Text>Für diese Publikation steht ein neuer <strong><Link className="text-secondary" to={`/publikation/${nextVersion.slugName}/${nextVersion.id}`}>Entwurf</Link></strong> zur Verfügung.</Text>
				</Alert>
			</div>
		</div>
	)
}
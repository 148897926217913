import React from 'react';
import {Modal, Row, Col, Table} from 'react-bootstrap';
import qs from 'qs';

import Button, {subscriptionIntervalUnitLabel} from 'common/Button';
import Loading from 'common/Loading';
import {EntityLink} from 'common/Links';
import Form, {
	Input,
	SimpleSelect as Select,
	Hidden,
	useFormContext,
} from 'common/Form';
import {CancelIcon, AddIcon, DownloadIcon, ShippingIcon} from 'common/Icons';

import {useTranslation, Trans} from 'react-i18next';
import {useMutation, useQuery, useEffect, useLocation, useHistory} from 'hooks';
import {
	mutationAddToBasket,
	queryGetPublicationDetail,
	queryGetCollectionDetail,
} from 'graph';
import {calcPrice, /*calcPriceFromDiscounts,*/ formatPrice} from 'utils';

function useField(name) {
	const {watch} = useFormContext();
	return watch(name);
}
const useType = () => useField('type');
const useQuantity = () => parseInt(useField('quantity'));
const useSubscription = () => {
	const value = useField('subscription');
	return value && JSON.parse(value);
};
const useExtraDownload = () => {
	const value = useField('extraDownload');
	return value && JSON.parse(value);
};
const useBundleId = () => useField('bundle.id');
const useBundle = (bundles = []) => {
	const id = useBundleId();
	if (id === '') {
		return null;
	}

	return bundles.find((bundle) => bundle.id === id);
};

function Shipping({product}) {
	const {t} = useTranslation('common');
	const {setValue} = useFormContext();
	const type = useType();

	const {orderMode} = product;
	const material = product.material.number;

	const options = [];

	if (orderMode === 'SHIPPING' || orderMode === 'BOTH') {
		options.push({
			value: 'SHIPPING',
			label: t('modals.basket.ordering_print'),
		});
	}

	if (
		orderMode === 'DIGITAL' ||
		orderMode === 'BOTH' ||
		orderMode === 'SERVICE'
	) {
		options.push({
			value: 'DIGITAL',
			label:
				orderMode === 'SERVICE'
					? t('modals.basket.ordering_service')
					: t('modals.basket.ordering_digital'),
		});
	}

	useEffect(() => {
		if (type === 'SHIPPING' && material === 'S+S') {
			setValue('extraDownload', false);
		}
	}, [material, type, setValue]);

	useEffect(() => {
		if (type === 'DIGITAL') {
			setValue('extraDownload', false);
		}
	}, [type, setValue]);

	return (
		<>
			<h4>
				{t('modals.basket.ordering')}
				<br />
				<small className="h6 text-muted">
					{t('modals.basket.ordering_sub')}
				</small>
			</h4>
			<Select
				name="type"
				size="lg"
				placeholder={t('misc.please_select')}
				options={options}
			/>
		</>
	);
}

function Quantity() {
	const {t} = useTranslation('common');
	const type = useType();
	const isShipping = type === 'SHIPPING';

	const makeLabel = () => {
		if (!type) {
			return t('modals.basket.quantity_sub_all');
		}

		return isShipping
			? t('modals.basket.quantity_sub_pieces')
			: t('modals.basket.quantity_sub_licenses');
	};

	return (
		<>
			<h4>
				{t('modals.basket.quantity')}
				<br />
				<small className="h6 text-muted">{makeLabel()}</small>
			</h4>
			<Input
				type="number"
				min={1}
				name="quantity"
				disabled={!type}
				size="lg"
				rules={{
					required: isShipping
						? t('modals.basket.quantity_help_shipping')
						: t('modals.basket.quantity_help_digital'),
				}}
			/>
		</>
	);
}

function Subscription({product}) {
	const {t} = useTranslation('common');
	const type = useType();
	const quantity = useQuantity();

	if (!type) {
		return null;
	}

	if (product.subscriptionMode === 'DISABLED') {
		return <Hidden name="subscription" />;
	}

	const required = product.subscriptionMode === 'FORCE_ENABLED';
	const options = [];

	if (required) {
		options.push({
			value: true,
			label: t('modals.basket.subscription_yes'),
		});
	} else {
		options.push({
			value: false,
			label: t('modals.basket.subscription_no'),
		});
		options.push({
			value: true,
			label: t('modals.basket.subscription_yes'),
		});
	}

	function makeLabel() {
		if (required) {
			return type === 'SHIPPING'
				? t('modals.basket.subscription_sub_required_shipping', {
						count: quantity,
				  })
				: t('modals.basket.subscription_sub_required_digital');
		}

		return type === 'SHIPPING'
			? t('modals.basket.subscription_sub_shipping', {count: quantity})
			: t('modals.basket.subscription_sub_digital');
	}

	function makeTitle() {
		return required
			? t('modals.basket.subscription_required')
			: t('modals.basket.subscription');
	}

	return (
		<>
			<h4 className="mt-3">
				{makeTitle()}
				<br />
				<small className="h6 text-muted">{makeLabel()}</small>
			</h4>
			<SSAlert product={product} />
			<Select
				name="subscription"
				size="lg"
				defaultValue={options[0]}
				options={options}
			/>
		</>
	);
}

const DEFAULT_DISCOUNT = 80;
const ITEM_DISCOUNTS = {
	'S+S': 100,
};

const getExtraDownloadDiscount = (product) =>
	ITEM_DISCOUNTS[product.material.number] || DEFAULT_DISCOUNT;

function ExtraDownload({product}) {
	const {t} = useTranslation('common');
	const type = useType();

	if (type !== 'SHIPPING' || product.orderMode !== 'BOTH' || product.material.number === 'S+S') {
		return <Hidden name="extraDownload" />;
	}

	const options = [
		{value: false, label: t('modals.basket.additional_download_no')},
		{value: true, label: t('modals.basket.additional_download_yes')},
	];

	const discount = getExtraDownloadDiscount(product);

	return (
		<Row>
			<Col>
				<h4 className="mt-3">
					{t('modals.basket.additional_download')}
					<br />
					<small className="h6 text-muted">
						{t('modals.basket.additional_download_sub', {
							discount,
						})}
					</small>
				</h4>
				<Select
					name="extraDownload"
					size="lg"
					options={options}
				/>
			</Col>
		</Row>
	);
}

function SubscriptionInterval({plan}) {
	const {t} = useTranslation('common');
	const subscription = useSubscription();
	if (!plan || !subscription) {
		return null;
	}

	return (
		<small className="d-block text-muted">
			{t('buttons.per')} {subscriptionIntervalUnitLabel(t, plan.cycle)}
		</small>
	);
}

function Price({product}) {
	const {bundles, price, subscriptionPlan: plan} = product;

	const {t} = useTranslation('common');
	const type = useType();
	const quantity = useQuantity();
	const subscription = useSubscription();
	const extraDownload = useExtraDownload();
	const bundle = useBundle(bundles);

	if (!type) {
		return null;
	}

	function calculateDiscount() {
		if (type === 'DIGITAL' && product.material.number === 'S+S') {
			return 0.8;
		}

		if (subscription && plan) {
			return (100 - plan.cycle.discount.value) / 100;
		}

		if (bundle) {
			return (100 - bundle.discount.value) / 100;
		}

		return 1;
	}

	const discount = calculateDiscount();
	const extraDownloadDiscount = getExtraDownloadDiscount(product);
	const basePrice = price.price;
	const bestPrice = calcPrice(price.prices, quantity).price * discount;
	const advantage = Math.round((1 - bestPrice / basePrice) * 100);

	return (
		<Row>
			<Col>
				<h4>{t('modals.basket.price')}</h4>
				<Table>
					<thead>
						<tr>
							<th width={20} />
							<th>{t('modals.basket.price_single_price')}</th>
							<th>{t('modals.basket.price_discount')}</th>
							<th>{t('modals.basket.price_total')}</th>
							<th>{t('modals.basket.price_advantage')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<span className="d-block">
									{type === 'DIGITAL' ? (
										<DownloadIcon size={16} />
									) : (
										<ShippingIcon size={16} />
									)}
								</span>
							</td>
							<td>
								{formatPrice(bestPrice)} EUR{' '}
								<SubscriptionInterval plan={plan} />
							</td>
							<td>{advantage > 0 ? `${advantage} %` : '-'}</td>
							<td>{formatPrice(bestPrice * quantity)} EUR</td>
							<td className="text-success font-weight-bold">
								{advantage > 0
									? `${formatPrice(
											quantity * (basePrice - bestPrice)
									  )}  EUR`
									: '-'}
							</td>
						</tr>
						{extraDownload && (
							<tr>
								<td>
									<span className="d-block">
										<DownloadIcon size={16} />
									</span>
								</td>
								<td>
									{formatPrice(
										(basePrice *
											(100 - extraDownloadDiscount)) /
											100
									)}{' '}
									EUR <SubscriptionInterval plan={plan} />
								</td>
								<td>{extraDownloadDiscount}%</td>
								<td>
									{formatPrice(
										(basePrice *
											(100 - extraDownloadDiscount)) /
											100
									)}{' '}
									EUR
								</td>
								<td className="text-success font-weight-bold">
									{formatPrice(
										(basePrice * extraDownloadDiscount) /
											100
									)}{' '}
									EUR
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<p className="text-muted font-italic">
					{t('modals.basket.price_sub')}
				</p>
			</Col>
		</Row>
	);
}

function Bundles({product}) {
	const {t} = useTranslation('common');
	const type = useType();
	const quantity = useQuantity();
	const subscription = useSubscription();
	const extraDownload = useExtraDownload();
	const bundleId = useBundleId();
	const {register} = useFormContext();

	const bundles = (product.bundles || []).filter(
		({maxQuantity, orderMode}) => {
			if (maxQuantity < quantity) {
				return false;
			}

			if (orderMode === 'SHIPPING' && type === 'DIGITAL') {
				return false;
			}

			if (orderMode === 'DIGITAL' && type === 'SHIPPING') {
				return false;
			}

			return true;
		}
	);

	if (bundles.length === 0) {
		return null;
	}

	if (!type || subscription || extraDownload) {
		return null;
	}

	function renderBundleText(t, bundle) {
		const discount = bundle.discount.value;
		return (
			<Trans t={t} key={bundle.id} l18nKey="modal.basket.bundles">
				<span key={bundle.id}>
					Sie erhalten{' '}
					<strong className="text-primary">{bundle} % Rabatt</strong>{' '}
					auf diesen Artikel sowie auf{' '}
					<ul className="list-unstyled mb-0">
						{bundle.publications.map((pub) => (
							<li>
								<EntityLink to={pub} target="_blanc">
									{pub.title} ({pub.name})
								</EntityLink>{' '}
								für{' '}
								<span className="text-success">
									{formatPrice(
										(pub.price.price * (100 - discount)) /
											100
									)}{' '}
									EUR
								</span>{' '}
								statt <del>{pub.price.priceFormated}</del>.
							</li>
						))}
					</ul>
				</span>
			</Trans>
		);
	}

	return (
		<Row>
			<Col className="mb-4">
				<h4>Passende Angebote</h4>
				<div>
					{bundles.map((bundle) => (
						<div key={bundle.id} className="form-check">
							<input
								ref={register}
								className="form-check-input"
								type="radio"
								value={bundle.id}
								name="bundle.id"
								id={`bundle-${bundle.id}`}
							/>
							<label
								className="form-check-label"
								for={`bundle-${bundle.id}`}
							>
								{renderBundleText(t, bundle)}
							</label>
						</div>
					))}
					{bundleId && (
						<div className="form-check">
							<input
								ref={register}
								className="form-check-input"
								type="radio"
								value=""
								name="bundle.id"
								id="bundle-none"
							/>
							<label
								className="form-check-label"
								for="bundle-none"
							>
								<span className="text-muted">
									Ich möchte doch kein Angebot nutzen.
								</span>
							</label>
						</div>
					)}
				</div>
			</Col>
		</Row>
	);
}

function SubmitButton({loading}) {
	const {t} = useTranslation('common');
	const {watch} = useFormContext();
	const item = watch(['type', 'quantity', 'license', 'id']);
	const disabled = !(item.type && (item.quantity || item.license));

	return (
		<Button
			disabled={loading || disabled}
			type="submit"
			icon={<AddIcon className="vds-button-img" size={28} />}
		>
			{item.id ? t('buttons.update') : t('buttons.add_to_basket')}
		</Button>
	);
}

function SSAlert({product}) {
	const {t} = useTranslation('common');
	if (product?.material.number !== 'S+S') {
		return null;
	}

	return (
		<div className="alert alert-info">
			{t('modals.basket.additional_download_ss')}
		</div>
	);
}

const DEFAULT_ITEM = {
	quantity: 1,
	extraDownload: false,
};

const ITEM_TEMPLATES = {
	'S+S': {
		...DEFAULT_ITEM,
	},
};

function Body({item, handleClose, handleBasket}) {
	const {t} = useTranslation('common');
	const [addToBasket, {loading: loadingAdd}] =
		useMutation(mutationAddToBasket);
	const {data: dataPublication, loading: loadingPublication} = useQuery(
		queryGetPublicationDetail,
		{
			variables: {
				id: item.product.id,
			},
		}
	);
	const {data: dataCollection, loading: loadingCollection} = useQuery(
		queryGetCollectionDetail,
		{
			variables: {
				id: item.product.id,
			},
		}
	);

	const loading = loadingPublication || loadingCollection;

	if (loading) {
		return <Loading />;
	}

	const product = dataPublication?.publication || dataCollection?.collection;

	const onSubmit = (input) => {
		input.subscription = JSON.parse(input.subscription);
		input.extraDownload = JSON.parse(input.extraDownload);

		if (!input.id) {
			input.id = null;
		}

		addToBasket({
			variables: {input},
		}).then(handleBasket);
	};

	return (
		<Form
			onSubmit={onSubmit}
			defaultValues={{
				...(ITEM_TEMPLATES[product.material.number] || DEFAULT_ITEM),
				...item,
				subscription: product.subscriptionMode === 'FORCE_ENABLED',
			}}
		>
			<Hidden name="product.id" />
			<Hidden name="id" />
			<Modal.Body>
				<Row>
					<Col>
						<Shipping product={product} />
					</Col>
					<Col>
						<Quantity price={product.price} />
					</Col>
				</Row>
				<Subscription product={product} />
				<ExtraDownload product={product} />
				<Bundles product={product} />
				<Price product={product} />
			</Modal.Body>

			<Modal.Footer>
				<Row>
					<Col>
						<Button
							styles={{width: '50%'}}
							type="secondary"
							onClick={handleClose}
							icon={
								<CancelIcon
									className="vds-button-img"
									size={28}
								/>
							}
						>
							{t('buttons.close')}
						</Button>
					</Col>
					<Col>
						<SubmitButton
							styles={{width: '50%'}}
							loading={loading || loadingAdd}
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</Form>
	);
}

export default function AddToBasketModal({item, show, handleClose}) {
	const {t} = useTranslation('common');
	const {pathname: return_to} = useLocation();
	const {push} = useHistory();

	if (!show || !item) {
		return null;
	}

	const handleBasket = () => {
		if (!item.id) {
			push(
				`/warenkorb?${qs.stringify({
					return_to,
				})}`
			);
		}

		handleClose();
	};

	return (
		<Modal size="lg" show={show} onHide={handleClose}>
			<>
				<Modal.Header closeButton>
					<Modal.Title>
						{item.id
							? t('modals.basket.title_update')
							: t('modals.basket.title')}
					</Modal.Title>
				</Modal.Header>
				<Body
					item={item}
					handleClose={handleClose}
					handleBasket={handleBasket}
				/>
			</>
		</Modal>
	);
}

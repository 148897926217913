import React from 'react'
import {useUser} from 'hooks'
import {ActivateLicense} from 'pages/Account'

export default function LicenseAlert({product, refetchQueries = []})
{
	const user = useUser()
	const {licenses, checkout} = product

	if (!user || checkout || licenses.length === 0) {
		return null
	}

	return (
		<ActivateLicense
			licenses={licenses}
			refetchQueries={refetchQueries}
		/>
	)
}
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get} from 'lodash'
import cn from 'classnames'

const Check = ({
	id,
	name, 
	label, 
	help,
	rules,
	...props
}) => {

	const {register, errors} = useFormContext()
	return (
		<div className="custom-control custom-checkbox">
			<input
				{...props}
				type="checkbox"
				ref={register(rules)} 
				className={cn('custom-control-input', {'is-invalid': get(errors, name)})} 
				name={name}
				id={id || name}
			/>
			<label className="custom-control-label" htmlFor={name}>
				{label}
				{help && <><br/><small className="text-muted">{help}</small></>}
			</label>
			<ErrorMessage name={name} errors={errors}>
				{({ message }) => <div className="invalid-feedback">{message}</div>}
			</ErrorMessage>
		</div>
	)
}

export default Check
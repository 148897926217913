import React from 'react'
import { Link } from '../../common/Links'
import cover from 'img/header_webshop_1440x500px_2847-09_2847-12.jpg'

export function SlideBooks()
{
	return (
		<Link to="/publikation/vds-2847-09">
			<img
				className="d-block w-100"
				src={cover}
				alt="Brandschutzanlagen, Teil 1: Grundlagen und Wasserlöschanlagen"
			/>
		</Link>
	);
}

import React from 'react'
import VdSButton from 'common/Button'
import {Link} from 'common/Links'
import {ArrowLeft} from 'react-feather'
import {useQueryParams, useLocation, useQuery} from 'hooks'
import Fuse from 'fuse.js'
import {fullPublicationId} from 'utils'
import {queryGetSearch} from 'graph'

const IconStartpage = () => <ArrowLeft className="vds-button-img" size={28}/>

function Suggestion({keyword})
{
	const {data, loading} = useQuery(queryGetSearch)

	if (loading) {
		return null
	}

	const publications = data?.publications || []
	const collections  = data?.collections || []

	const products = [...publications, ...collections];

	const fuse = new Fuse(products, {
		keys: [
			{
				name: 'number',
				weight: 3
			},
			{
				name: 'name',
				weight: 2
			},
			'title'
		]
	})

	const result = fuse.search(keyword).slice(0, 3).map(({item}) => item)

	if (result.length === 0) {
		return null
	}

	return (
		<>
			<h2>Wollten Sie vielleicht hier hin?</h2>
			{result.map(item => {
				const {title, slugName, __typename} = item

				return (
					<p><Link to={`/${__typename === 'Publication' ? 'publikation' : 'sammelwerk'}/${slugName}`}>
						{fullPublicationId(item)}
					</Link><br/>{title}</p>
				)
			})}
		</>
	)
}

export default function NotFound() {
	const {requested_pathname} = useQueryParams()
	const {pathname} = useLocation()

	const path = requested_pathname || pathname || ''
	const keyword = path.trim('/').split('/').pop()

	return (
		<section id="content" className="content-full-width">
			<div className="position-relative grid-fw-bg grid-fw-minh">
				<div className="bg-content" style={{zIndex: 0}}>
					<div className="frame frame-default frame-type-image frame-layout-0">
						<div className="ce-image ce-center ce-above">
							<div className="ce-gallery" data-ce-columns="1" data-ce-images="1">
								<img className="img-fluid" src="https://vds.de/fileadmin/Website_Content_Images/404_Fehler_Webseite.svg" alt="" style={{minHeight: 980, visibility: 'hidden'}} width="1394" height="707"/>
							</div>
						</div>
					</div>
					<div className="frame frame-default frame-type-html frame-layout-0"/>
				</div>
				<div className="container position-relative fg-content" style={{zIndex: 1}}>
					<div className="row">
						<div className="col-12 fg-content-inner">
							<Link to="/">
								<VdSButton 
									icon={<IconStartpage/>}
								>Zurück zur Startseite</VdSButton>
							</Link>
						</div>
						<div className="frame frame-default frame-type-text frame-layout-0" style={{width: '80%'}}>
							<h1>Die Seite wurde nicht gefunden.</h1>
							<h2>Fehler 404</h2>
							<p>Im Rahmen des Relaunchs unseres Webshops kann es passieren, dass alte Links nicht mehr korrekt funktionieren. Wir arbeiten daran, die gewohnten Links so weit wie möglich wiederherzustellen.</p>
							<p>Vielen Dank für Ihr Verständnis.</p>
							{keyword && <Suggestion keyword={keyword} />}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
import React, {useState, useEffect} from 'react';
import {
	useCategories,
	useUser,
	useBasket,
	useShopContext,
	useHistory,
	useTranslation,
} from 'hooks';
import {Link, EntityLink} from 'common/Links';
import {Collapse} from 'react-bootstrap';
import RequestLoginModal from 'common/RequestLoginModal';
import $ from 'jquery';
// Icons
import {
	Search as IconSearch,
	ShoppingBag as IconBasket,
	Bookmark as IconPinboard,
	Menu as MenuIcon,
	Globe as IconLang,
	X as XIcon,
} from 'react-feather';

import logo from '../../.vds/Public/Images/vds-logo-de.jpg';

function initializeNavigation() {
	var $headerTop = $('#header-top');
	var $navTop = $headerTop.find('.navbar').first();
	var $navTopContent = $navTop.find('.navbar-collapse').first();
	var $navMainDropdowns = $navTopContent.find(
		'.nav-main > .nav-item.dropdown'
	);
	var $allDropdowns = $navTopContent.find('.dropdown');
	var $window = $(window);
	var testBreakpoint = function (breakpoint) {
		var res = $('.breakpoint-test-' + breakpoint).css('display');
		return res && res !== 'none';
	};
	var setBodyState = function (state) {
		$('body').toggleClass('nav-open', state);
	};
	/*open/close submenus in mobile fullscreen-nav*/
	$allDropdowns.each(function () {
		var $dropdown = $(this);
		$dropdown.children('.open-submenu').click(function () {
			openSubmenu($dropdown);
		});
		$dropdown
			.children('.dropdown-menu')
			.children('.parent-item')
			.click(function () {
				closeSubmenu($dropdown);
			});
	});
	var openSubmenu = function ($dropdown, traverseUp) {
		$dropdown.children('.dropdown-menu').addClass('show');
		$dropdown.closest('.show').addClass('overflow-hidden'); //needed to solve problems with overflow-y:auto and stacked position:fixed divs
		if (traverseUp === true) {
			var $parentDropdown = $dropdown
				.parent()
				.closest('.dropdown');
			if ($parentDropdown.length) openSubmenu($parentDropdown);
		}
	};
	var closeSubmenu = function ($dropdown) {
		$dropdown
			.children('.dropdown-menu')
			.removeClass('show')
			.closest('.show')
			.removeClass('overflow-hidden');
	};
	var gotoCurrentMobile = function ($nav) {
		if (testBreakpoint('lg')) return;
		var activeItemsSortedByDepth = $nav
			.find('.active')
			.toArray()
			.sort(function (a, b) {
				return $(a).data('level') < $(b).data('level') ? 1 : -1;
			});
		if (activeItemsSortedByDepth.length) {
			var $item = $(activeItemsSortedByDepth[0]);
			if ($item.hasClass('dropdown-item'))
				openSubmenu($item.closest('.dropdown'), true);
		}
	};
	/*add class to body when expanding mobile fullscreen-nav*/
	$navTopContent.on('shown.bs.collapse', function () {
		setBodyState(!testBreakpoint('lg'));
		gotoCurrentMobile($navTopContent);
	});
	$navTopContent.on('hide.bs.collapse', function () {
		setBodyState(false);
		$navTopContent.add($allDropdowns).removeClass('overflow-hidden');
	});
	/*desktop hover styling that was not possible with pure css*/
	$navMainDropdowns.each(function () {
		var $this = $(this);
		var $dropdown = $this.find('.dropdown-menu').first();

		$this.click(function () {
			$this.removeClass('show');
			$headerTop.removeClass('dropdown-open');
		});

		/* ugly hack because we need additional css on parents of hover items*/
		$this.hover(
			function () {
				if (testBreakpoint('lg')) {
					$this.delay(100).queue(function () {
						$this.addClass('show');
						$headerTop.addClass('dropdown-open');
						var left = $dropdown.offset().left;
						$dropdown
							.width($('body').innerWidth())
							.css('padding-left', left)
							.css('margin-left', -left);
						$dropdown
							.find('.indicator')
							.css(
								'max-width',
								$dropdown
									.closest('nav-item')
									.width()
							)
							.css('left', left);
						$(this).dequeue();
					});
				}
			},
			function () {
				$this.removeClass('show');
				$headerTop.removeClass('dropdown-open');
				$dropdown.css('padding', '').css('margin', '');
				$this.clearQueue();
			}
		);
		var $hasnavicon = $this.find('[data-navicon]');

		$hasnavicon.hover(
			function () {
				var windowwdth = window.innerWidth;
				if (windowwdth > 991) {
					//$iconval = $(this).attr("data-navicon");
					//$showsicon.find('[src="' + $iconval + '"]').addClass("activeIcon");
				}
			},
			function () {
				//$iconval = $(this).attr("data-navicon");
				//$showsicon.find('[src="' + $iconval + '"]').removeClass("activeIcon");
			}
		);
	});

	/*remove nojs class */
	$headerTop.removeClass('nojs');
	/*toggle shrink-class */
	$window.on('load scroll', function () {
		$headerTop.toggleClass('scrolled', window.pageYOffset > 75);
	});
	$window.on('resize', function () {
		/*remove body class for open menu when resizing mobile => desktop*/
		setBodyState(
			!testBreakpoint('lg') && $navTopContent.hasClass('show')
		);
		/*close expanded dropdowns when resizing  mobile => desktop*/
		if (testBreakpoint('lg')) {
			$allDropdowns.find('.dropdown-menu').removeClass('show');
		}
	});
	$navTopContent.doubleTapToGo();
	gotoCurrentMobile($navTopContent);
}

const main = ['Brandschutz', 'Security'];

function LangItem() {
	const {i18n} = useTranslation('common');
	function toggleLang() {
		if (i18n.language === 'de') {
			i18n.changeLanguage('en');
		} else {
			i18n.changeLanguage('de');
		}
	}

	return (
		<li className="nav-item icon-append">
			<button
				className="btn btn-link nav-link"
				id="lang-desktop"
				onClick={toggleLang}
			>
				<span>{i18n.language === 'de' ? 'Deutsch' : 'English'}</span>
				<IconLang />
			</button>
		</li>
	);
}

function BasketBubble() {
	const basket = useBasket();
	const {items} = basket;

	if (!items.length) {
		return null;
	}

	return <i className="bubble">{items.length}</i>;
}

function PinboardBubble() {
	const user = useUser();

	if (!user || user.pins.length === 0) {
		return null;
	}

	return <i className="bubble">{user.pins.length}</i>;
}

function PinboardNavItem() {
	const user = useUser();
	const {t} = useTranslation('common');
	const [showLogin, setShowLogin] = useState(false);

	function onClick(event) {
		event.preventDefault();
		setShowLogin(true);
	}

	if (!user) {
		return (
			<>
				<RequestLoginModal
					show={showLogin}
					handleClose={(e) => setShowLogin(false)}
				/>
				<li className="nav-item icon-append">
					<a
						className="nav-link"
						title={t('navigation.pinboard')}
						href="#login-required"
						onClick={onClick}
					>
						<span>{t('navigation.pinboard')}</span>
						<IconPinboard />
						<PinboardBubble />
					</a>
				</li>
			</>
		);
	}

	return (
		<li className="nav-item icon-append">
			<Link
				className="nav-link"
				title={t('navigation.pinboard')}
				to="/benutzerkonto/infodienst"
			>
				<span>{t('navigation.pinboard')}</span>
				<IconPinboard />
				<PinboardBubble />
			</Link>
		</li>
	);
}

export default function Navigation() {
	const history = useHistory();
	const {t, i18n} = useTranslation('common');
	const {t: ct} = useTranslation('category');
	const categories = useCategories();
	const {collections, branches} = useShopContext();
	const user = useUser();
	const [open, setOpen] = useState(false);
	const filtered = categories.tree.filter((c) => main.includes(c.name));
	// Misc
	filtered.push({
		id: 'misc',
		name: 'Weitere Themen',
		children: categories.tree.filter((c) => !main.includes(c.name)),
	});
	useEffect(() => {
		initializeNavigation();
	}, []);

	history.listen(() => setOpen(false));
	const sortCategories = (a, b) => {
		if (a.name === 'Brandschutz') {
			return 1;
		}

		if (a.name > b.name) {
			return 1;
		}

		if (a.name < b.name) {
			return -1;
		}

		return 0;
	};

	return (
		<header
			className="container-full corner-collapse fixed-top"
			id="header-top"
		>
			<div className="breakpoint-test-lg d-none d-lg-block position-absolute" />
			<nav
				className="navbar navbar-light navbar-expand-lg"
				id="navbar-top"
			>
				<div className="navbar-brand">
					<Link to="/">
						<img
							className="company-icon"
							src={logo}
							alt="VdS Webshop"
						/>
					</Link>
				</div>
				<Collapse in={open} appear>
					<div
						className="collapse navbar-collapse"
						id="navbar-top-content"
					>
						<ul
							className="nav-main order-1 order-lg-2"
							id="navigation-root"
						>
							{filtered.sort(sortCategories).map((c) => (
								<li
									className="nav-item dropdown doubletap"
									key={c.id}
								>
									{c.id === 'misc' && (
										<a
											className="nav-link"
											href="#misc"
											onClick={(e) => e.preventDefault()}
										>
											{t('categories.misc')}
										</a>
									)}
									{c.id !== 'misc' && (
										<Link
											className="nav-link"
											to={`/kategorie/${c.slugPath}`}
										>
											{ct(c.id)}
										</Link>
									)}

									<div className="open-submenu mobile">
										<span
											className="nav-arrow-right-white"
											aria-label="vor"
											role="button"
										></span>
									</div>
									<ul className="dropdown-menu">
										<li className="indicator"></li>
										<li className="dropdown-item parent-item mobile">
											<span
												className="nav-arrow-left-blue"
												aria-label="zurück"
												role="button"
											></span>
											<span className="nav-link">
												{c.id === 'misc' ? t('categories.misc') : ct(c.id)}
											</span>
										</li>
										{c.children.map((s) => (
											<li
												className="dropdown-item prepend-icon dropdown doubletap"
												data-level="1"
												key={s.id}
											>
												<Link
													to={`/kategorie/${s.slugPath}`}
													className="nav-link"
												>
													<span className="nav-arrow-right-blue desktop"></span>
													{ct(s.id)}
												</Link>
											</li>
										))}
									</ul>
								</li>
							))}
							{i18n.language === 'de' && (
								<li className="nav-item dropdown doubletap">
									<a
										className="nav-link"
										href="#betriebsarten"
										onClick={(e) => e.preventDefault()}
									>
										{t('categories.modes')}
									</a>
									<div className="open-submenu mobile">
										<span
											className="nav-arrow-right-white"
											aria-label="vor"
											role="button"
										></span>
									</div>
									<ul className="dropdown-menu">
										<li className="indicator"></li>
										<li className="dropdown-item parent-item mobile">
											<span
												className="nav-arrow-left-blue"
												aria-label="zurück"
												role="button"
											></span>
											<span className="nav-link">
												Betriebsarten
											</span>
										</li>
										{branches.tree.map((s) => (
											<li
												className="dropdown-item prepend-icon dropdown doubletap"
												data-level="1"
												key={s.id}
											>
												<Link
													to={`/betriebsart/${s.slugPath}`}
													className="nav-link"
												>
													<span className="nav-arrow-right-blue desktop"></span>
													{s.name}
												</Link>
											</li>
										))}
									</ul>
								</li>
							)}
							<li className="nav-item dropdown doubletap">
								<a
									className="nav-link"
									href="#betriebsarten"
									onClick={(e) => e.preventDefault()}
								>
									{t('categories.collections')}
								</a>
								<div className="open-submenu mobile">
									<span
										className="nav-arrow-right-white"
										aria-label="vor"
										role="button"
									></span>
								</div>
								<ul className="dropdown-menu">
									<li className="indicator"></li>
									<li className="dropdown-item parent-item mobile">
										<span
											className="nav-arrow-left-blue"
											aria-label="zurück"
											role="button"
										></span>
										<span className="nav-link">
											{t('categories.collections')}
										</span>
									</li>
									{collections.map((c) => (
										<li
											className="dropdown-item prepend-icon dropdown doubletap"
											data-level="1"
											key={c.id}
										>
											<EntityLink
												to={c}
												className="nav-link"
											>
												<span className="nav-arrow-right-blue desktop"></span>
												{c.name} - {c.title}
											</EntityLink>
										</li>
									))}
								</ul>
							</li>
						</ul>
						<div className="nav-service-container order-2 order-lg-1">
							<ul className="nav-service-primary">
								<li className="nav-item">
									<a
										className="nav-link"
										href="https://vds.de/ueber-vds"
										target="_blanc"
									>
										{t('navigation.about')}
									</a>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										to="/kontakt"
										target="_self"
									>
										{t('navigation.contact')}
									</Link>
								</li>
								{!user && (
									<li className="nav-item">
										<a
											className="nav-link"
											href="/login"
											target="_self"
										>
											{t('navigation.login')}
										</a>
									</li>
								)}
								{user && (
									<>
										<li className="nav-item">
											<Link
												className="nav-link"
												to="/benutzerkonto"
												target="_self"
											>
												{t('navigation.account')}
											</Link>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												href="/logout"
												target="_self"
											>
												{t('navigation.logout')}
											</a>
										</li>
									</>
								)}
							</ul>
							<ul className="nav-service-secondary desktop">
								<LangItem />
								<li className="nav-item icon-append">
									<Link
										className="nav-link"
										title={t('navigation.basket')}
										to="/warenkorb"
									>
										<span>{t('navigation.basket')}</span>
										<IconBasket />
										<BasketBubble />
									</Link>
								</li>
								<PinboardNavItem />
								<li className="nav-item icon-append">
									<Link
										className="nav-link"
										title={t('navigation.search')}
										to="/#search"
									>
										<span>{t('navigation.search')}</span>
										<IconSearch />
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</Collapse>
				<button
					className="navbar-toggler"
					type="button"
					aria-expanded={open}
					aria-label="Menü"
					onClick={(e) => setOpen(!open)}
				>
					{open ? <XIcon /> : <MenuIcon />}
				</button>
				<ul className="nav-service-secondary mobile">
					<li className="nav-item icon-append">
						<Link
							className="nav-link"
							title={t('navigation.basket')}
							to="/warenkorb"
						>
							<span>{t('navigation.basket')}</span>
							<IconBasket />
							<BasketBubble />
						</Link>
					</li>
					<PinboardNavItem />
					<li className="nav-item icon-append">
						<Link
							className="nav-link"
							title={t('navigation.search')}
							to="/#search"
						>
							<span>{t('navigation.search')}</span>
							<IconSearch />
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}

import React from 'react'
import Select from './SimpleSelect'

const COUNTRIES_ALL = [
    {
        "value": "AF",
        "label": "Afghanistan"
    },
    {
        "value": "EG",
        "label": "\u00c4gypten"
    },
    {
        "value": "AL",
        "label": "Albanien"
    },
    {
        "value": "DZ",
        "label": "Algerien"
    },
    {
        "value": "AD",
        "label": "Andorra"
    },
    {
        "value": "AO",
        "label": "Angola"
    },
    {
        "value": "AG",
        "label": "Antigua und Barbuda"
    },
    {
        "value": "GQ",
        "label": "\u00c4quatorialguinea"
    },
    {
        "value": "AR",
        "label": "Argentinien"
    },
    {
        "value": "AM",
        "label": "Armenien"
    },
    {
        "value": "AZ",
        "label": "Aserbaidschan"
    },
    {
        "value": "ET",
        "label": "\u00c4thiopien"
    },
    {
        "value": "AU",
        "label": "Australien"
    },
    {
        "value": "BS",
        "label": "Bahamas"
    },
    {
        "value": "BH",
        "label": "Bahrain"
    },
    {
        "value": "BD",
        "label": "Bangladesch"
    },
    {
        "value": "BB",
        "label": "Barbados"
    },
    {
        "value": "BY",
        "label": "Wei\u00dfrussland"
    },
    {
        "value": "BE",
        "label": "Belgien"
    },
    {
        "value": "BZ",
        "label": "Belize"
    },
    {
        "value": "BJ",
        "label": "Benin"
    },
    {
        "value": "BT",
        "label": "Bhutan"
    },
    {
        "value": "BO",
        "label": "Bolivien"
    },
    {
        "value": "BA",
        "label": "Bosnien und Herzegowina"
    },
    {
        "value": "BW",
        "label": "Botswana"
    },
    {
        "value": "BR",
        "label": "Brasilien"
    },
    {
        "value": "BN",
        "label": "Brunei"
    },
    {
        "value": "BG",
        "label": "Bulgarien"
    },
    {
        "value": "BF",
        "label": "Burkina Faso"
    },
    {
        "value": "BI",
        "label": "Burundi"
    },
    {
        "value": "CL",
        "label": "Chile"
    },
    {
        "value": "CN",
        "label": "Volksrepublik China"
    },
    {
        "value": "CR",
        "label": "Costa Rica"
    },
    {
        "value": "DK",
        "label": "D\u00e4nemark"
    },
    {
        "value": "DM",
        "label": "Dominica"
    },
    {
        "value": "DO",
        "label": "Dominikanische Republik"
    },
    {
        "value": "DJ",
        "label": "Dschibuti"
    },
    {
        "value": "EC",
        "label": "Ecuador"
    },
    {
        "value": "CI",
        "label": "Elfenbeink\u00fcste"
    },
    {
        "value": "SV",
        "label": "El Salvador"
    },
    {
        "value": "ER",
        "label": "Eritrea"
    },
    {
        "value": "EE",
        "label": "Estland"
    },
    {
        "value": "SZ",
        "label": "Swasiland"
    },
    {
        "value": "FJ",
        "label": "Fidschi"
    },
    {
        "value": "FI",
        "label": "Finnland"
    },
    {
        "value": "FR",
        "label": "Frankreich"
    },
    {
        "value": "GA",
        "label": "Gabun"
    },
    {
        "value": "GM",
        "label": "Gambia"
    },
    {
        "value": "GE",
        "label": "Georgien"
    },
    {
        "value": "GH",
        "label": "Ghana"
    },
    {
        "value": "GD",
        "label": "Grenada"
    },
    {
        "value": "GR",
        "label": "Griechenland"
    },
    {
        "value": "GT",
        "label": "Guatemala"
    },
    {
        "value": "GN",
        "label": "Guinea"
    },
    {
        "value": "GW",
        "label": "Guinea-Bissau"
    },
    {
        "value": "GY",
        "label": "Guyana"
    },
    {
        "value": "HT",
        "label": "Haiti"
    },
    {
        "value": "HN",
        "label": "Honduras"
    },
    {
        "value": "IN",
        "label": "Indien"
    },
    {
        "value": "ID",
        "label": "Indonesien"
    },
    {
        "value": "IQ",
        "label": "Irak"
    },
    {
        "value": "IR",
        "label": "Iran"
    },
    {
        "value": "IE",
        "label": "Irland"
    },
    {
        "value": "IS",
        "label": "Island"
    },
    {
        "value": "IL",
        "label": "Israel"
    },
    {
        "value": "IT",
        "label": "Italien"
    },
    {
        "value": "JM",
        "label": "Jamaika"
    },
    {
        "value": "JP",
        "label": "Japan"
    },
    {
        "value": "YE",
        "label": "Jemen"
    },
    {
        "value": "JO",
        "label": "Jordanien"
    },
    {
        "value": "KH",
        "label": "Kambodscha"
    },
    {
        "value": "CM",
        "label": "Kamerun"
    },
    {
        "value": "CA",
        "label": "Kanada"
    },
    {
        "value": "CV",
        "label": "Kap Verde"
    },
    {
        "value": "KZ",
        "label": "Kasachstan"
    },
    {
        "value": "QA",
        "label": "Katar"
    },
    {
        "value": "KE",
        "label": "Kenia"
    },
    {
        "value": "KG",
        "label": "Kirgisistan"
    },
    {
        "value": "KI",
        "label": "Kiribati"
    },
    {
        "value": "CO",
        "label": "Kolumbien"
    },
    {
        "value": "KM",
        "label": "Komoren"
    },
    {
        "value": "CD",
        "label": "Kongo, Demokratische Republik"
    },
    {
        "value": "CG",
        "label": "Kongo, Republik"
    },
    {
        "value": "KP",
        "label": "Korea, Nord"
    },
    {
        "value": "KR",
        "label": "Korea, S\u00fcd"
    },
    {
        "value": "HR",
        "label": "Kroatien"
    },
    {
        "value": "CU",
        "label": "Kuba"
    },
    {
        "value": "KW",
        "label": "Kuwait"
    },
    {
        "value": "LA",
        "label": "Laos"
    },
    {
        "value": "LS",
        "label": "Lesotho"
    },
    {
        "value": "LV",
        "label": "Lettland"
    },
    {
        "value": "LB",
        "label": "Libanon"
    },
    {
        "value": "LR",
        "label": "Liberia"
    },
    {
        "value": "LY",
        "label": "Libyen"
    },
    {
        "value": "LI",
        "label": "Liechtenstein"
    },
    {
        "value": "LT",
        "label": "Litauen"
    },
    {
        "value": "LU",
        "label": "Luxemburg"
    },
    {
        "value": "MG",
        "label": "Madagaskar"
    },
    {
        "value": "MW",
        "label": "Malawi"
    },
    {
        "value": "MY",
        "label": "Malaysia"
    },
    {
        "value": "MV",
        "label": "Malediven"
    },
    {
        "value": "ML",
        "label": "Mali"
    },
    {
        "value": "MT",
        "label": "Malta"
    },
    {
        "value": "MA",
        "label": "Marokko"
    },
    {
        "value": "MH",
        "label": "Marshallinseln"
    },
    {
        "value": "MR",
        "label": "Mauretanien"
    },
    {
        "value": "MU",
        "label": "Mauritius"
    },
    {
        "value": "MX",
        "label": "Mexiko"
    },
    {
        "value": "FM",
        "label": "Mikronesien"
    },
    {
        "value": "MD",
        "label": "Moldau"
    },
    {
        "value": "MC",
        "label": "Monaco"
    },
    {
        "value": "MN",
        "label": "Mongolei"
    },
    {
        "value": "ME",
        "label": "Montenegro"
    },
    {
        "value": "MZ",
        "label": "Mosambik"
    },
    {
        "value": "MM",
        "label": "Myanmar"
    },
    {
        "value": "NA",
        "label": "Namibia"
    },
    {
        "value": "NR",
        "label": "Nauru"
    },
    {
        "value": "NP",
        "label": "Nepal"
    },
    {
        "value": "NZ",
        "label": "Neuseeland"
    },
    {
        "value": "NI",
        "label": "Nicaragua"
    },
    {
        "value": "NL",
        "label": "Niederlande"
    },
    {
        "value": "NE",
        "label": "Niger"
    },
    {
        "value": "NG",
        "label": "Nigeria"
    },
    {
        "value": "MK",
        "label": "Nordmazedonien"
    },
    {
        "value": "NO",
        "label": "Norwegen"
    },
    {
        "value": "OM",
        "label": "Oman"
    },
    {
        "value": "TL",
        "label": "Osttimor"
    },
    {
        "value": "PK",
        "label": "Pakistan"
    },
    {
        "value": "PW",
        "label": "Palau"
    },
    {
        "value": "PA",
        "label": "Panama"
    },
    {
        "value": "PG",
        "label": "Papua-Neuguinea"
    },
    {
        "value": "PY",
        "label": "Paraguay"
    },
    {
        "value": "PE",
        "label": "Peru"
    },
    {
        "value": "PH",
        "label": "Philippinen"
    },
    {
        "value": "PL",
        "label": "Polen"
    },
    {
        "value": "PT",
        "label": "Portugal"
    },
    {
        "value": "RW",
        "label": "Ruanda"
    },
    {
        "value": "RO",
        "label": "Rum\u00e4nien"
    },
    {
        "value": "RU",
        "label": "Russland"
    },
    {
        "value": "SB",
        "label": "Salomonen"
    },
    {
        "value": "ZM",
        "label": "Sambia"
    },
    {
        "value": "WS",
        "label": "Samoa"
    },
    {
        "value": "SM",
        "label": "San Marino"
    },
    {
        "value": "ST",
        "label": "S\u00e3o Tom\u00e9 und Pr\u00edncipe"
    },
    {
        "value": "SA",
        "label": "Saudi-Arabien"
    },
    {
        "value": "SE",
        "label": "Schweden"
    },
    {
        "value": "SN",
        "label": "Senegal"
    },
    {
        "value": "RS",
        "label": "Serbien"
    },
    {
        "value": "SC",
        "label": "Seychellen"
    },
    {
        "value": "SL",
        "label": "Sierra Leone"
    },
    {
        "value": "ZW",
        "label": "Simbabwe"
    },
    {
        "value": "SG",
        "label": "Singapur"
    },
    {
        "value": "SK",
        "label": "Slowakei"
    },
    {
        "value": "SI",
        "label": "Slowenien"
    },
    {
        "value": "SO",
        "label": "Somalia"
    },
    {
        "value": "ES",
        "label": "Spanien"
    },
    {
        "value": "LK",
        "label": "Sri Lanka"
    },
    {
        "value": "KN",
        "label": "St. Kitts und Nevis"
    },
    {
        "value": "LC",
        "label": "St. Lucia"
    },
    {
        "value": "VC",
        "label": "St. Vincent und die Grenadinen"
    },
    {
        "value": "ZA",
        "label": "S\u00fcdafrika"
    },
    {
        "value": "SD",
        "label": "Sudan"
    },
    {
        "value": "SS",
        "label": "S\u00fcdsudan"
    },
    {
        "value": "SR",
        "label": "Suriname"
    },
    {
        "value": "SY",
        "label": "Syrien"
    },
    {
        "value": "TJ",
        "label": "Tadschikistan"
    },
    {
        "value": "TZ",
        "label": "Tansania"
    },
    {
        "value": "TH",
        "label": "Thailand"
    },
    {
        "value": "TG",
        "label": "Togo"
    },
    {
        "value": "TO",
        "label": "Tonga"
    },
    {
        "value": "TT",
        "label": "Trinidad und Tobago"
    },
    {
        "value": "TD",
        "label": "Tschad"
    },
    {
        "value": "CZ",
        "label": "Tschechien"
    },
    {
        "value": "TN",
        "label": "Tunesien"
    },
    {
        "value": "TR",
        "label": "T\u00fcrkei"
    },
    {
        "value": "TM",
        "label": "Turkmenistan"
    },
    {
        "value": "TV",
        "label": "Tuvalu"
    },
    {
        "value": "UG",
        "label": "Uganda"
    },
    {
        "value": "UA",
        "label": "Ukraine"
    },
    {
        "value": "HU",
        "label": "Ungarn"
    },
    {
        "value": "UY",
        "label": "Uruguay"
    },
    {
        "value": "UZ",
        "label": "Usbekistan"
    },
    {
        "value": "VU",
        "label": "Vanuatu"
    },
    {
        "value": "VE",
        "label": "Venezuela"
    },
    {
        "value": "AE",
        "label": "Vereinigte Arabische Emirate"
    },
    {
        "value": "US",
        "label": "Vereinigte Staaten"
    },
    {
        "value": "VN",
        "label": "Vietnam"
    },
    {
        "value": "CF",
        "label": "Zentral\u00adafrikanische Republik"
    },
    {
        "value": "CY",
        "label": "Zypern"
    }
]

const COUNTRIES_TOP = [
    {
        "value": "DE",
        "label": "Deutschland"
    },
    {
        "value": "AT",
        "label": "\u00d6sterreich"
    },
    {
        "value": "CH",
        "label": "Schweiz"
    },
    {
        "value": "GB",
        "label": "Vereinigtes K\u00f6nigreich"
    }
]

const SelectCountry = props => (
	<Select
		{...props}
		options={[...COUNTRIES_TOP, ...COUNTRIES_ALL]}
	/>
)

export default SelectCountry
$(function(){
    var $headerTop = $("#header-top");
    var $navTop = $headerTop.find(".navbar").first();
    var $navTopContent = $navTop.find(".navbar-collapse").first();
    var $navMainDropdowns = $navTopContent.find(".nav-main > .nav-item.dropdown");
    var $allDropdowns = $navTopContent.find(".dropdown");
    var $window = $(window);
    var testBreakpoint = function (breakpoint) {
        var res = $(".breakpoint-test-" + breakpoint).css("display");
        return (res && res != "none");
    };
    var setBodyState = function (state) {
        $("body").toggleClass("nav-open", state);
    };
    /*open/close submenus in mobile fullscreen-nav*/
    $allDropdowns.each(function(){
        var $dropdown = $(this);
        $dropdown.children(".open-submenu").click(function(){
            openSubmenu($dropdown);
        });
        $dropdown.children(".dropdown-menu").children('.parent-item').click(function(){
            closeSubmenu($dropdown);
        });
    });
    var openSubmenu = function($dropdown, traverseUp) {
        $dropdown.children(".dropdown-menu").addClass("show");
        $dropdown.closest('.show').addClass('overflow-hidden'); //needed to solve problems with overflow-y:auto and stacked position:fixed divs
        if (traverseUp === true) {
            var $parentDropdown = $dropdown.parent().closest('.dropdown');
            if ($parentDropdown.length)
                openSubmenu($parentDropdown);
        }
    };
    var closeSubmenu = function($dropdown) {
        $dropdown.children('.dropdown-menu').removeClass("show").closest('.show').removeClass('overflow-hidden');
    };
    var gotoCurrentMobile = function($nav) {
        if (testBreakpoint('lg')) return;
        var activeItemsSortedByDepth = $nav.find('.active')
            .toArray()
            .sort(function(a,b){
                return ($(a).data('level') < $(b).data('level')) ? 1 : -1;
            });
        if (activeItemsSortedByDepth.length) {
            var $item = $(activeItemsSortedByDepth[0]);
            if ($item.hasClass('dropdown-item')) openSubmenu($item.closest('.dropdown'), true);
        }
    };
    /*add class to body when expanding mobile fullscreen-nav*/
    $navTopContent.on("shown.bs.collapse", function(){
        setBodyState(!testBreakpoint("lg"));
        gotoCurrentMobile($navTopContent);
    });
    $navTopContent.on("hide.bs.collapse", function() {
        setBodyState(false);
        $navTopContent.add($allDropdowns).removeClass('overflow-hidden');
    });
    /*desktop hover styling that was not possible with pure css*/
    $navMainDropdowns.each(function(){
        var $this = $(this);
        var $dropdown = $this.find(".dropdown-menu").first();
        /* ugly hack because we need additional css on parents of hover items*/
        $this.hover(function(){
            if (testBreakpoint("lg")) {
                $this.delay(100).queue(function(){
                    $this.addClass("show");
                    $headerTop.addClass("dropdown-open");
                    var left = $dropdown.offset().left;
                    $dropdown.width($("body").innerWidth())
                        .css("padding-left", left)
                        .css("margin-left", -left);
                    $dropdown.find(".indicator").css("max-width", $dropdown.closest(".nav-item").width()).css("left", left);
                    $(this).dequeue();
                });
            }
        }, function(){
            $this.removeClass("show");
            $headerTop.removeClass("dropdown-open");
            $dropdown.css("padding", "").css("margin", "");
            $this.clearQueue();
        });
        var $hasnavicon = $this.find("[data-navicon]");
        var $showsicon = $this.find(".iconfield");

		$hasnavicon.hover(function(){
            var windowwdth = window.innerWidth;
            if(windowwdth > 991) {
                //console.log('icon');
                //console.log(windowwdth);
                $iconval = $(this).attr("data-navicon");
                $showsicon.find('[src="'+$iconval+'"]').addClass("activeIcon");
            }

		}, function(){
			$iconval = $(this).attr("data-navicon");
			$showsicon.find('[src="'+$iconval+'"]').removeClass("activeIcon");
		});
    });
		

    /*remove nojs class */
    $headerTop.removeClass("nojs");
    /*toggle shrink-class */
    $window.on("load scroll", function() {
        $headerTop.toggleClass("scrolled", (window.pageYOffset > 75));
    });
    $window.on("resize", function(){
        /*remove body class for open menu when resizing mobile => desktop*/
        setBodyState(!testBreakpoint("lg") && $navTopContent.hasClass("show"));
        /*close expanded dropdowns when resizing  mobile => desktop*/
        if (testBreakpoint("lg")) {
            $allDropdowns.find(".dropdown-menu").removeClass("show");
        }
    });
    $navTopContent.doubleTapToGo();
    gotoCurrentMobile($navTopContent);
});
import React, {useState} from 'react'
import {Row, Col, Card} from 'react-bootstrap'
import {CreditCard, Eye} from 'react-feather'

import {get} from 'lodash'
import cn from 'classnames'
import Form, {Input, SelectCountry, Radio} from 'common/Form'
import Button, {RequiresLoginButton} from 'common/Button'


import {useMutation, useUser, useBasket, useFormContext} from 'hooks'
import {useTranslation, Trans} from 'react-i18next';
import {mutationUpdateBasketAddresses} from 'graph'

const IconPayment = () => <CreditCard className="vds-button-img" size={28}/>
const IconCheck = () => <Eye className="vds-button-img" size={28}/>


function AddressBlock({prefix, disabled}) {
  const {t} = useTranslation('basket');
  const {defaultValues} = useFormContext()
  const [more, setMore] = useState(!!(defaultValues.name2 || defaultValues.name3, defaultValues.name4))
  const [poBox, setPoBox] = useState(!!(defaultValues.poBox || defaultValues.poBoxZip))
  return (
    <>
      {disabled && (
        <Trans t={t} i18nKey="address.alert_disabled">
          <p>Ihre Rechnungsanschrift wurde unseren Kundenstammdaten entnommen. Möchten Sie eine Änderung Ihrer Stammdaten beauftragen, wenden Sie sich bitte an <a href="mailto:verlag@vds.de">verlag@vds.de</a>.</p>
        </Trans>
      )}
      <Input
        name={`${prefix}.name`}
        placeholder={t('address.name.placeholder')}
        maxLength="35"
        size="lg"
        rules={{required: t('address.name.help')}}
      />
      <div className={cn({'d-none': !more})}>
        <Input
          name={`${prefix}.name2`}
          placeholder={t('address.name2.placeholder')}
          maxLength="35"
          size="lg"
        />
        <Input
          name={`${prefix}.name3`}
          placeholder={t('address.name3.placeholder')}
          maxLength="35"
          size="lg"
        />
        <Input
          name={`${prefix}.name4`}
          placeholder={t('address.name4.placeholder')}
          maxLength="35"
          size="lg"
        />
      </div>
      {!more && (
        <div className="mb-3"><a href="#showmore" onClick={e => e.preventDefault() || setMore(true)}>{t('address.show_more')}</a></div>
      )}
      {!poBox && (
        <>
          <Input
            name={`${prefix}.street`}
            placeholder={t('address.street.placeholder')}
            maxLength="35"
            size="lg"
            rules={{required: t('address.street.help')}}
          />
          <div className="form-row">
            <Col xs={3}>
              <Input
                name={`${prefix}.zip`}
                placeholder={t('address.zip.placeholder')}
                size="lg"
                rules={{required: t('address.zip.help')}}
              />
            </Col>
            <Col>
              <Input
                name={`${prefix}.city`}
                placeholder={t('address.city.placeholder')}
                size="lg"
                rules={{required: t('address.city.help')}}
              />
            </Col>
          </div>
          <SelectCountry
            name={`${prefix}.countryCode`}
            placeholder={t('address.country.placeholder')}
            size="lg"
            rules={{required: t('address.country.help')}}
          />
          <div className="mb-3"><a href="#showpobox" onClick={e => e.preventDefault() || setPoBox(true)}>{t('address.use_pobox')}</a></div>
        </>
      )}
      {poBox && (
        <>
          <div className="form-row">
            <Col>
              <Input
                name={`${prefix}.poBox`}
                placeholder={t('address.pobox.placeholder')}
                size="lg"
                rules={{required: t('address.pobox.help')}}
              />
            </Col>
            <Col xs={3}>
              <Input
                name={`${prefix}.poBoxZip`}
                placeholder={t('address.pobox_zip.placeholder')}
                size="lg"
                rules={{required: t('address.pobox_zip.help')}}
              />
            </Col>
          </div>
          <SelectCountry
            name={`${prefix}.countryCode`}
            placeholder={t('address.country.placeholder')}
            size="lg"
            rules={{required: t('address.country.help')}}
          />
          <div className="mb-3"><a href="#showstreet" onClick={e => e.preventDefault() || setPoBox(false)}>{t('address.use_address')}</a></div>
        </>
      )}
    </>
  )
}

function AddressInvoice() {
  const {t} = useTranslation('basket');
  const {customer} = useUser()
  return (
    <>
      <h2>{t('address.billing_address')}</h2>
      <AddressBlock prefix="invoiceAddress" disabled={!!customer}/>
    </>
  )
}

function AddressDelivery() {
  const {t} = useTranslation('basket');
  const {watch} = useFormContext()
  const same = watch('same', '1')
  if (same === '1') {
    return null
  }

  return (
    <>
      <h2 className="mt-3">{t('address.shipping_address')}</h2>
      <AddressBlock prefix="recipientAddress"/>
    </>
  )
}

export default function Address({next, prev}) {
  const {t} = useTranslation('basket');
  const [updateBasketAddress, {error, loading}] = useMutation(mutationUpdateBasketAddresses, {
    onCompleted: () => next(),
    onError: () => {}
  })

  const user = useUser()
  const basket = useBasket()
  const onSubmit = input => {
    input.same = input.same === '1' ? true : false
    updateBasketAddress({
      variables: {
        input
      }
    })
  }
  return (
    <div>
      <Form 
        onSubmit={onSubmit}
        error={error}
        defaultValues={{
          purchaseNumber: basket.purchaseNumber,
          same: basket.recipientAddress.name ? '0' : '1',
          invoiceAddress: (basket.invoiceAddress.name && basket.invoiceAddress) || get(user, 'customer.address') || user.companyAddress,
          recipientAddress: (basket.recipientAddress.name && basket.recipientAddress)
        }}
      >
        <AddressInvoice/>
        <Card className="vds-card">
          <Card.Body>
            <div className="form-row">
              <Col>
                <Radio 
                  name="same" 
                  value="1" 
                  id="same"
                  label={t('address.address_same')}
                  defaultChecked
                />
              </Col>
              <Col>
                <Radio 
                  name="same" 
                  value="0"
                  id="not_same" 
                  label={t('address.address_different')}
                />
              </Col>
            </div>
          </Card.Body>
        </Card>
        <AddressDelivery/>
        <Row>
          <Col>
            <h2 className="my-4">{t('address.purchase_number.title')}</h2>
            <Input
              size="lg"
              name="purchaseNumber"
              placeholder={t('address.purchase_number.placeholder')}
              rules={{
                maxLength: {
                  value: 35,
                  message: t('address.purchase_number.help')
                }
              }}
            />
          </Col>
          <Col>
            <h2 className="my-4">{t('address.voucher.title')}</h2>
            <Input
              size="lg"
              name="voucher"
              placeholder={t('address.voucher.placeholder')}
              rules={{
                maxLength: {
                  value: 6,
                  message: t('address.voucher.help')
                },
                minLength: {
                  value: 6,
                  message: t('address.voucher.help')
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button 
              onClick={() => prev()}
              icon={<IconCheck/>}
              disabled={loading}
            >{t('address.prev')}</Button>
          </Col>
          <Col>
            <RequiresLoginButton
              type="submit"
              icon={<IconPayment/>}
              disabled={loading}
            >{t('address.next')}</RequiresLoginButton>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
import React from 'react'
import {EntityLink} from 'common/Links'
import {useState} from 'hooks'
import {formatPrice} from 'utils'
import {useTranslation, Trans} from 'react-i18next';

/*
const INTERVAL_TEXT = {
	YEAR:  ({intervalCount}) => `${intervalCount === 1 ? 'einem Jahr'  : intervalCount + ' Jahren'}`,
	MONTH: ({intervalCount}) => `${intervalCount === 1 ? 'einem Monat' : intervalCount + ' Monaten'}`,
	WEEK:  ({intervalCount}) => `${intervalCount === 1 ? 'einer Woche' : intervalCount + ' Wochen'}`,
	DAY:   ({intervalCount}) => `${intervalCount === 1 ? 'einem Tag'   : intervalCount + ' Tagen'}`,
}
*/


function SubscriptionText({plan, mode, price})
{
	const {t} = useTranslation('common');

	if (mode === 'DISABLED') {
		return null
	}

	const advantage = plan && price * plan.cycle.discount.value / 100

	if (mode === 'FORCE_ENABLED') {
		return (
			<p className="my-1">
				<Trans t={t} i18nKey="pricealert.subscription_only">Dieser Artikel ist <strong>nur</strong> im Abonnement erhältlich.</Trans>
				{' '}
				{plan
					? t('pricealert.subscription_extension_plan', {
						interval: t('pricealert.interval.' + plan.cycle.intervalUnit.toLowerCase(), {count: plan.cycle.intervalCount})
					})
					: t('pricealert.subscription_extension_manually')}
				{advantage > 0 && ' ' + t('pricealert.advantage', {advantage: formatPrice(advantage)})}
			</p>
		)
	}

	return (
		<p className="my-1">
			{t('pricealert.subscription_available')}
				{plan
					? t('pricealert.subscription_extension_plan', {
						interval: t('pricealert.interval.' + plan.cycle.intervalUnit.toLowerCase(), {count: plan.cycle.intervalCount})
					})
					: t('pricealert.subscription_extension_manually')}
				{advantage > 0 && ' ' + t('pricealert.advantage', {advantage: formatPrice(advantage)})}
		</p>
	)
}

function PriceText({price})
{
	const {t} = useTranslation('common');
	const [showDetails, setShowDetails] = useState(false)
	const toggleMore = e => {
		e.preventDefault()
		setShowDetails(true)
	}

	if (price.prices.length < 2) {
		return null
	}

	return (
		<>
			<p className="my-1">{t('pricealert.scale_price')} {!showDetails && (<a onClick={toggleMore} href="#show-details">{t('misc.show_more')}</a>)}</p>
			{showDetails && (
				<ol className="list-unstyled my-1">
					{price.prices.slice(1).map(({amount, priceFormated}) => (
						<li key={amount}>{t('pricealert.scale_price_item', {
							amount,
							priceFormated
						})}</li>
					))}
				</ol>
			)}
		</>
	)
}

function BundleText({bundles})
{
	const {t} = useTranslation('common');

	const [showDetails, setShowDetails] = useState(false)
	const toggleMore = e => {
		e.preventDefault()
		setShowDetails(true)
	}

	if (!bundles?.length) {
		return null
	}

	function renderBundleText(bundle) {
		return (
			<li key={bundle.id}>Sie erhalten <strong>{bundle.discount.value} % Rabatt</strong> auf diesen Artikel sowie auf <ul className="list-unstyled mb-0">{bundle.publications.map(pub => (
				<li>
					<EntityLink to={pub} target="_blanc">
						{pub.name}: {pub.title}
					</EntityLink>
					{" "}
					/ <span className="text-success">{formatPrice(pub.price.price * (100 - bundle.discount.value) / 100)} EUR</span> statt <del>{pub.price.priceFormated}</del>.
				</li>
			))}</ul></li>
		)
	}

	return (
		<>
			<p className="my-1">Zu diesem Artikel gibt es Kombinationsangebote. {!showDetails && (<a onClick={toggleMore} href="#show-details">{t('misc.show_more')}</a>)}</p>
			{showDetails && (
				<ol className="list-unstyled my-1">
					{bundles.map(bundle => renderBundleText(bundle))}
				</ol>
			)}
		</>
	)
}

export default function PriceAndSubscriptionAlert({product})
{
	const {t} = useTranslation('common');
	const {price, bundles, orderMode, subscriptionMode, subscriptionPlan} = product
	const titles = []

	if (orderMode === 'DISABLED') {
		return null
	}

	if (price.prices.length > 1) {
		titles.push(t('pricealert.prices'));
	}

	if (subscriptionMode !== 'DISABLED') {
		titles.push(t('pricealert.subscriptions'))
	}

	if (bundles?.length > 0) {
		titles.push(t('pricealert.offers'))
	}

	if (titles.length === 0) {
		return null
	}

	const title = titles.length > 2
		? `${titles.slice(0, -1).join(', ')} ${t('misc.and')} ${titles.slice(-1)}`
	  : titles.join(` ${t('misc.and')} `)

	return (
		<div className="vds-quicklinks-box my-0">
			<div className="vds-quicklinks-box-header">
				<h1>{t('pricealert.title', {title})}</h1>
			</div>
			<div className="row py-1">
				<div className="col">
					<SubscriptionText
						mode={subscriptionMode}
						plan={subscriptionPlan}
						price={price.price}
					/>
					<PriceText
						price={price}
					/>
					<BundleText
						bundles={bundles}
					/>
				</div>
			</div>
		</div>
	)
}
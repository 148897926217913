import React, { useEffect } from 'react'
import {useForm, useFormContext, FormProvider} from 'react-hook-form'
import {noop} from 'lodash'

export {
	useForm,
	useFormContext,
	FormProvider 
}

export {default as Input} from './form/Input'
export {default as Textarea} from './form/Textarea'
export {default as Select} from './form/Select'
export {default as SimpleSelect} from './form/SimpleSelect'
export {default as SelectCountry} from './form/SelectCountry'
export {default as SelectLicense} from './form/SelectLicense'
export {default as Radio} from './form/Radio'
export {default as Switch} from './form/Switch'
export {default as Hidden} from './form/Hidden'

export function ExternalForm({onSubmit, children, methods, ...rest}) {
	const {handleSubmit, ...otherMethods} = methods
	const onInnerSubmit = (data, e) => onSubmit(data, otherMethods, e)


	return (
		<form {...rest} onSubmit={handleSubmit(onInnerSubmit)}>
			<FormProvider  {...otherMethods}>
				{children}
			</FormProvider >
		</form>
	)
}

export default function Form({error, defaultValues, onSubmit = noop, readOnly = false, children}) {
	const {handleSubmit, setError, ...methods} = useForm({
		defaultValues
	})

	const onInnerSubmit = (data, e) => onSubmit(data, methods, e)

	useEffect(() => {
		if (error) {
			const validationErrors = error.graphQLErrors.filter(
				error => error.category === 'validation'
			).reduce(
				(stack, item) => ({...stack, ...item.invalid}),
				{}
			);

			Object.entries(validationErrors).forEach(
				([name, message]) => setError(name, {
					type: 'server_validation',
						message
				})
			);
		}
	}, [error, setError])

	return (
		<form onSubmit={handleSubmit(onInnerSubmit)}>
			<FormProvider  {...methods} setError={setError} defaultValues={defaultValues} readOnly={readOnly}>
				{children}
			</FormProvider >
		</form>
	)
}
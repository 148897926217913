import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useShopContext} from '../../hooks'
import {useTranslation} from 'react-i18next';

export default function RequestLoginModal({show, handleClose}) {
	const {urls} = useShopContext();
	const {t} = useTranslation('common');
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{t('modals.login.title')}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p>{t('modals.login.body')}</p>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="light" onClick={handleClose}>{t('buttons.cancel')}</Button>
				<Button onClick={() =>  {window.location.href = urls.login}}>{t('buttons.login')}</Button>
			</Modal.Footer>
		</Modal>
	)
}
import { useContext } from 'react'
import ShopContext from '../context/ShopContext'

export default function useShopContext() {
  const context =  useContext(ShopContext)

  if (context === true) {
  	return null
  }

  return context
}


import React from 'react'
import {EntityLink} from 'common/Links'
import {cn} from 'utils'
import {useTranslation, Trans} from 'react-i18next'

const WITHDRAWN_REASONS = {
	WITHOUT_SUBSTITUTION: (p,t) => t('statealert.withdrawn.without_substitution'),
	REPLACED: ({withdrawnRelation}, t) => (
		<>
			{t('statealert.withdrawn.replaced')} <PublicationLink
				publication={withdrawnRelation?.publication}
				variant="danger"
			/>.
		</>
	),
	MERGED: ({withdrawnRelation}, t) => (
		<>
			{t('statealert.withdrawn.merged')} <PublicationLink
				publication={withdrawnRelation?.publication}
				variant="danger"
			/>.
		</>
	),
	MISSING_TRANSLATION: ({withdrawnRelation}, t) => (
		<>
			{t('statealert.withdrawn.missing_translation')} <PublicationLink
				publication={withdrawnRelation?.publication}
				variant="danger"
			/>.
		</>
	),
}

function PublicationLink({publication, variant, children}) {
	const {t} = useTranslation('common');
	if (!publication) {
		return (
			<i>{t('statealert.details_follow')}</i>
		)
	}

	return (
		<EntityLink to={publication} className={`text-${variant}`}>
			<strong>{children || publication.name}</strong>
		</EntityLink>
	)
}

const Text = ({children}) => <p className="my-1">{children}</p>

function Alert({title, children, variant})
{
	return (
			<div className="vds-quicklinks-box my-0">
				<div className="vds-quicklinks-box-header">
					<h1 className={cn(variant && `text-${variant}`)}>{title}</h1>
				</div>
				<div className={cn('row', 'py-1', variant && `text-${variant}`, variant && `border-${variant}`)}>
					<div className="col">
						{children}
					</div>
				</div>
			</div>
	)
}


export default function StateAlert({product}) {
	const {state} = product
	const {t} = useTranslation('common');

	if (state === 'DRAFT') {
		return (
			<Alert title="Entwurf">
				<Text>{t('statealert.draft')}</Text>
			</Alert>
		)
	}

	if (state === 'OUTDATED') {
		return (
			<Alert title="Veraltet" variant="warning">
				<Text>
					<Trans t={t} i18nKey="statealert.outdated">
						Zu dieser Publikation existiert eine <PublicationLink publication={product} variant="warning">aktuellere Auflage</PublicationLink>.
					</Trans>
				</Text>
			</Alert>
		)
	}

	if (state === 'WITHDRAWN') {
		const {withdrawnReason, withdrawnComment} = product
		return (
			<Alert title={t('statealert.withdrawn.header')} variant="danger">
				<Text>{WITHDRAWN_REASONS[withdrawnReason || 'WITHOUT_SUBSTITUTION'](product, t)}</Text>
				{withdrawnComment && (
					<Text>{withdrawnComment}</Text>
				)}
			</Alert>
		)
	}

	return null
}
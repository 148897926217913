import {ApolloClient, InMemoryCache}  from '@apollo/client'

export const cache = new InMemoryCache({
	possibleTypes: {
		"Product": [
			"Publication",
			"Collection"
		],
		"ProductInterface": [
			"Publication",
			"License",
			"Collection"
		]
	}
})

const client = new ApolloClient({
	connectToDevTools: process.env.NODE_ENV !== 'production',
	uri: process.env.REACT_APP_API_URI,
	credentials: 'include', //'same-origin'
	cache
})

/*
export const basketVar = cache.makeVar({
	items: []
})
*/

export default client
import React from 'react';
import {
	Table,
	Row,
	Col,
	Form,
	InputGroup,
	OverlayTrigger,
	Tooltip,
	Button,
} from 'react-bootstrap';
import {useTranslation, Trans} from 'react-i18next';
import {Link} from './Links';
import Loading from 'common/Loading';
import {
	Download as DownloadIcon,
	ShoppingBag as CartIcon,
	Bookmark as PinboardIcon,
	Search as SearchIcon,
	//	ArrowDown
} from 'react-feather';
import AddToBasketModal from './AddToBasketModal';
import RequestLoginModal from './RequestLoginModal';

import {
	mutationAddMaterialToPinboard,
	mutationRemovePinFromPinboard,
} from 'graph';
import {useState, useMutation} from 'hooks';
import {day, fullPublicationId, cn} from 'utils';
import {useUser} from '../../hooks';

function Filter({
	placeholder,
	keyword,
	setKeyword,
	filter,
	setFilter,
	order,
	setOrder,
}) {
	const {t} = useTranslation('common');
	return (
		<div>
			{setKeyword && (
				<Row className="mb-0">
					<Col>
						<InputGroup className="mb-2 mr-sm-2">
							<InputGroup.Prepend>
								<InputGroup.Text
									style={{
										backgroundColor: '#fff',
										borderRight: '0 solid',
									}}
								>
									<SearchIcon />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								value={keyword}
								onChange={(e) => setKeyword(e.target.value)}
								size="lg"
								type="text"
								placeholder={
									placeholder || t('category.search_placeholder')
								}
								style={{borderLeft: '0 solid'}}
							/>
						</InputGroup>
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					{setFilter && (
						<Form.Control
							as="select"
							onChange={(e) => setFilter(e.target.value)}
							value={filter}
						>
							<option value="all">
								{t('category.filter_option_all')}
							</option>
							<option value="new">
								{t('category.filter_option_new')}
							</option>
						</Form.Control>
					)}
				</Col>
				<Col>
					{setOrder && (
						<Form.Control
							as="select"
							onChange={(e) => setOrder(e.target.value)}
							value={order}
						>
							<option value="name">
								{t('category.order_option_number')}
							</option>
							<option value="date">
								{t('category.order_option_release')}
							</option>
						</Form.Control>
					)}
				</Col>
			</Row>
		</div>
	);
}

function ButtonBar({
	publication,
	handleShowBasket,
	handleShowBookmark,
	handleShowLogin,
}) {
	return (
		<div className="d-flex justify-content-between align-right">
			<AddToBasketButton
				handleShow={handleShowBasket}
				publication={publication}
			/>
			<AddToPinboardButton
				handleShowLogin={handleShowLogin}
				publication={publication}
			/>
			<DownloadButton publication={publication} />
		</div>
	);
}

function AddToPinboardButton({publication, handleShowLogin}) {
	const user = useUser();
	const {t} = useTranslation('common');
	const {material} = publication;

	const [pin, {loading: pinning}] = useMutation(
		mutationAddMaterialToPinboard,
		{
			variables: {
				input: {
					material: {
						number: material?.number,
					},
				},
			},
		}
	);
	const [unpin, {loading: unpinning}] = useMutation(
		mutationRemovePinFromPinboard,
		{
			variables: {
				id: publication.pin?.id,
			},
			update(cache, {data}) {
				const normalizedId = cache.identify(data.pinboardRemovePin);
				cache.evict({id: normalizedId});
				cache.gc();
			},
		}
	);

	const isPinned = !!publication.pin;

	function toggle(event) {
		event.preventDefault();

		if (!user) {
			handleShowLogin();
			return;
		}

		if (pinning || unpinning) {
			return;
		}

		if (isPinned) {
			unpin();
		} else {
			pin();
		}
	}

	const title = isPinned ? t('buttons.remove_from_pinboard') : t('buttons.add_to_pinboard');

	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip>
					{isPinned && (
						<Trans t={t} i18nKey="buttons.tooltip.unpinned">
							<h6>
								<strong>
									Kostenlosen Infodienst für diesen Artikel
									kündigen.
								</strong>
							</h6>
							<p className="mb-0">
								Sie erhalten keine weiteren Nachrichten zu neuen Auflagen.
							</p>
						</Trans>
					)}
					{!isPinned && (
						<Trans t={t} i18nKey="buttons.tooltip.unpinned">
							<h6>
								<strong>
									Kostenlosen Infodienst für diesen Artikel
									abonnieren.
								</strong>
							</h6>
							<p className="mb-0">
								Wir benachrichtigen Sie per E-Mail, wenn neue
								Auflagen oder Entwürfe erscheinen.
							</p>
						</Trans>
					)}
				</Tooltip>
			}
		>
			<Button
				as="a"
				disabled={pinning || unpinning}
				variant="link"
				title={title}
				onClick={toggle}
				className={cn('text-primary', {'text-success': isPinned})}
			>
				<PinboardIcon />
			</Button>
		</OverlayTrigger>
	);
}

function AddToBasketButton({handleShow, publication}) {
	const {t} = useTranslation('common');
	const {orderMode} = publication;

	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id="add-to-basket-tooltip">
					{t('buttons.tooltip.add_to_basket')}
				</Tooltip>
			}
		>
			<Button
				disabled={orderMode === 'DISABLED'}
				variant="link"
				onClick={(e) => e.preventDefault() || handleShow(publication)}
			>
				<CartIcon />
			</Button>
		</OverlayTrigger>
	);
}

function DownloadButton({publication}) {
	const {t} = useTranslation('common');
	const {actionUrl, actionLicenseLevel} = publication;

	if (!actionUrl) {
		return (
			<Button disabled={true} variant="link">
				<DownloadIcon />
			</Button>
		);
	}

	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id="add-to-basket-tooltip">
					{actionLicenseLevel === 'FULL'
						? t('buttons.tooltip.download_full')
						: t('buttons.tooltip.download_free')}
				</Tooltip>
			}
		>
			<Button
				variant="link"
				as="a"
				target="_blanc"
				className={cn('text-primary', {
					'text-success': actionLicenseLevel === 'FULL',
				})}
				href={actionUrl}
			>
				<DownloadIcon />
			</Button>
		</OverlayTrigger>
	);
}

function PublicationRow({publication, handleShowLogin, handleShow}) {
	const {title, slugName, state, __typename} = publication;
	const isWithdrawn = state === 'WITHDRAWN';
	return (
		<tr>
			<td>
				<h5>
					<Link
						to={`/${
							__typename === 'Publication'
								? 'publikation'
								: 'sammelwerk'
						}/${slugName}`}
					>
						{fullPublicationId(publication)}
					</Link>{' '}
					{isWithdrawn && (
						<span
							className="badge badge-danger"
							style={{position: 'relative', top: -3}}
						>
							Zurückgezogen
						</span>
					)}
					<br />
					<small className="text-muted">{title}</small>
				</h5>
			</td>
			<td>
				{__typename === 'Publication' && (
					<ButtonBar
						publication={publication}
						handleShowBasket={handleShow}
						handleShowBookmark={handleShow}
						handleShowLogin={handleShowLogin}
					/>
				)}
			</td>
		</tr>
	);
}

export function PublicationList({
	publications,
	placeholder,
	loading,
	disableFilter,
}) {
	const [item, setItem] = useState({});
	const [show, setShow] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [order, setOrder] = useState(disableFilter ? 'none' : 'name');
	const [filter, setFilter] = useState('all');

	const isNewDate = day().subtract(3, 'month');

	const handleClose = () => {
		setShow(false);
		setItem({});
	};

	const handleShow = (publication) => {
		setItem({
			product: {
				id: publication.id,
			},
		});
		setShow(true);
	};

	const handleShowLogin = () => setShowLogin(true);
	const filtered = publications
		.filter((p) =>
			`${p.name} ${p.title}`.toLowerCase().includes(keyword.toLowerCase())
		)
		.filter((p) => {
			switch (filter) {
				case 'new':
					return day(p.releaseDate).isAfter(isNewDate);
				case 'all':
				default:
					return true;
			}
		})
		.sort((a, b) => {
			switch (order) {
				case 'date':
					return day(a.releaseDate).isAfter(b.releaseDate) ? -1 : 1;
				case 'name':
					return ('' + a.name).localeCompare(b.name);
				default:
					return 0;
			}
		});

	return (
		<div>
			<AddToBasketModal
				show={show}
				handleClose={handleClose}
				item={item}
			/>
			<RequestLoginModal
				show={showLogin}
				handleClose={(e) => setShowLogin(false)}
			/>
			{!disableFilter && (
				<Filter
					keyword={keyword}
					setKeyword={setKeyword}
					order={order}
					setOrder={setOrder}
					filter={filter}
					setFilter={setFilter}
					placeholder={placeholder}
				/>
			)}
			{loading && <Loading text="Inhalte werden geladen" />}
			{!loading && filtered.length === 0 && (
				<h2>
					{keyword.trim() === ''
						? 'Diese Liste ist leer'
						: `Ihre Suche nach ${keyword} lieferte kein Ergebnis`}
				</h2>
			)}
			{!loading && filtered.length > 0 && (
				<Row>
					<Col>
						<Table hover>
							<thead>
								<tr>
									<td>
										<strong>Titel</strong>
									</td>
									<td width="120" />
								</tr>
							</thead>
							<tbody>
								{filtered.map((publication) => (
									<PublicationRow
										key={publication.id}
										publication={publication}
										handleShow={handleShow}
										handleShowLogin={handleShowLogin}
									/>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>
			)}
		</div>
	);
}

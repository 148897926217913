import {Link as BaseLink, NavLink as BaseNavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export {Redirect} from 'react-router-dom';

const ENTITY_ID_MAP = {
	Publication: 'slugName',
	Collection: 'slugName',
	Category: 'slugPath'
}

const ENTITY_PATH_MAP = {
	Publication: 'publikation',
	Collection: 'sammelwerk',
	Category: 'kategorie'
}

function useLangPrefixedTo(to='')
{
	const {i18n} = useTranslation();

	// Dont change static links
	if (to.startsWith('http')) {
		return to;
	}

	// Dont change links having a lang code
	if (to.match(/^\/?(de|en)/i)) {
		return to;
	}

	if (to.charAt(0) === '/') {
		return '/' + i18n.language + to;
	} else {
		return i18n.language + '/' + to;
	}
}

export function Link(props)
{
	const to = useLangPrefixedTo(props.to);
	return (
		<BaseLink {...props} to={to} />
	)
}

export function NavLink(props)
{
	const to = useLangPrefixedTo(props.to);
	return (
		<BaseNavLink {...props} to={to} />
	)
}

export function getEntityUrl(entity, relative=true)
{
	const {__typename: name} = entity
	const path = `/${ENTITY_PATH_MAP[name] || name.toLowerCase()}/${entity[ENTITY_ID_MAP[name] || 'id']}`
	return relative
		? path
		: process.env.REACT_APP_SHOP_URI + path
}

export function EntityLink({to, ...props})
{
	return <Link to={getEntityUrl(to)} {...props} />
}
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get} from 'lodash'
import cn from 'classnames'

const Radio = ({
	id,
	name, 
	label, 
	help,
	rules,
	value,
	...rest
}) => {

	const {register, errors} = useFormContext()

	return (
		<div className="custom-control custom-radio">
			<input 
				{...rest}
				type="radio"
				ref={register(rules)} 
				className={cn('custom-control-input', {'is-invalid': get(errors, name)})} 
				value={value}
				name={name}
				id={id}
			/>
			<label className="custom-control-label" htmlFor={id}>
				{label}
				{help && <><br/><small className="text-muted">{help}</small></>}
			</label>
			<ErrorMessage name={name} errors={errors}>
				{({ message }) => <div className="invalid-feedback">{message}</div>}
			</ErrorMessage>
		</div>
	)
}

export default Radio
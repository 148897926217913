import React from 'react'
import { Link } from '../../common/Links'
import cover from 'img/header_webshop_1440x500px_2573.jpg'

export function Slide2573()
{
	return (
		<Link to="/sammelwerk/vds-2573">
			<img
				className="d-block w-100"
				src={cover}
				alt="VdS 2573 - Sammelwerk Brandschutz und Sicherungstechnik"
			/>
		</Link>
	);
}

import React from 'react';
import Keyvisual from 'common/Keyvisual';
import UserCard from 'common/UserCard';
import Loading from 'common/Loading';
import {
    Redirect,
    Switch,
    Route,
    useRouteMatch
} from 'react-router-dom';
import {Button, ButtonGroup, Card, Row, Col, Nav} from 'react-bootstrap';
import {DownloadIcon, ShippingIcon} from 'common/Icons';
import {Link, NavLink, EntityLink, getEntityUrl} from 'common/Links';
import Form, {Input, SelectLicense, useForm, FormProvider} from 'common/Form';
import {useTranslation, Trans} from 'react-i18next';

import {useUser, useQuery, useMutation} from 'hooks';
import {
    queryGetUserOrders,
    queryGetUserSubscriptions,
    queryGetUserLicenses,
    queryGetUserPinboard,
    mutationActivateLicense,
    mutationActivateLicenseCode,
    mutationRemovePinFromPinboard,
} from 'graph';
import {cn, day, calcVat, formatPrice, redirect} from 'utils';
import {partition} from 'lodash';

const NOW = new Date();

function Positions({positions, subscription}) {
    const {t} = useTranslation('account');
    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">{t('position_table.position')}</th>
                    <th scope="col">{t('position_table.description')}</th>
                    {!subscription && (
                        <th scope="col">{t('position_table.vat')}</th>
                    )}
                    <th scope="col" className="text-right">
                        {t('position_table.quantity')}
                    </th>
                    {!subscription && (
                        <th scope="col" className="text-right">
                            {t('position_table.total')}
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {positions &&
                    positions.map((position) => (
                        <Position
                            key={position.id}
                            subscription={subscription}
                            position={position}
                        />
                    ))}
            </tbody>
        </table>
    );
}

function Position({position, subscription}) {
    const {t} = useTranslation('account');
    const {item, quantity, taxes, amount, product, material, download} =
        position;
    return (
        <tr>
            <th scope="row">{item}</th>
            <td>
                <h5>
                    <small className="text-muted">
                        {product?.name || material.number} -{' '}
                        <ShippingBatch download={download} />
                    </small>
                    <br />
                    {product && (
                        <EntityLink to={product}>{product.title}</EntityLink>
                    )}
                    {!product && material.description}
                </h5>
                {!product && (
                    <div className="d-inline-block px-2 py-1 text-danger border border-danger rounded">
                        <Trans t={t}>
                            <strong>Hinweis:</strong> Der Artikel dieser
                            Position ist nicht im Webshop verfügbar.
                        </Trans>
                    </div>
                )}
            </td>
            {!subscription && <td align="center">{calcVat(amount, taxes)}</td>}
            <td align="right">{quantity}</td>
            {!subscription && (
                <td align="right">{formatPrice(amount)}&nbsp;EUR</td>
            )}
        </tr>
    );
}

function ShippingBatch({download}) {
    const {t} = useTranslation('account');
    return download ? (
        <span>
            {t('download')}{' '}
            <DownloadIcon style={{position: 'relative', top: -3}} size={17} />
        </span>
    ) : (
        <span>
            {t('shipping')}{' '}
            <ShippingIcon style={{position: 'relative', top: -3}} size={17} />
        </span>
    );
}

const BreadCrumbs = () => {
    const {t} = useTranslation('account');
    return (
        <div className="row breadcrumb-wrapper">
            <div className="col-12">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" title={t('breadcrumbs.home')}>
                                <span>{t('breadcrumbs.home')}</span>
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link
                                to="/benutzerkonto"
                                title={t('breadcrumbs.account')}
                            >
                                <span>{t('breadcrumbs.account')}</span>
                            </Link>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    );
};

function Orders() {
    const {t} = useTranslation('account');
    const {data, loading} = useQuery(queryGetUserOrders);
    if (loading) {
        return <Loading text={t('orders.loading')} />;
    }

    const ordersUser = data?.user.orders;
    // const ordersCustomer = (data?.user.customer?.orders || []).filter(({number}) => !ordersUser.some(order => order.number === number))

    // <h2>Weitere Bestellungen mit dieser Kundennummer</h2>
    // {ordersCustomer.map(order => <Order key={order.number} order={order}/>)}

    return (
        <div>
            <h2>{t('orders.title')}</h2>
            {ordersUser.map((order) => (
                <Order key={order.number} order={order} />
            ))}
            {ordersUser.length === 0 && (
                <div className="text-muted">{t('orders.empty')}</div>
            )}
        </div>
    );
}

export function Order({order}) {
    const {t} = useTranslation('account');
    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header className="border-bottom-0">
                        <Row className="mb-0">
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('order_table.number')}
                                </small>
                                <br />
                                {order.number}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('order_table.date')}
                                </small>
                                <br />
                                {day(order.createdAt).format('DD.MM.YYYY')}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('order_table.purchase_number')}
                                </small>
                                <br />
                                {order.purchaseNumber || '-'}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('order_table.total')}
                                </small>
                                <br />
                                {t('order_table.total_detail', {
                                    amount: formatPrice(order.amount),
                                    taxes: formatPrice(order.taxes),
                                })}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Positions positions={order.positions} />
                    {!!order.subscription && (
                        <Card.Footer>
                            {t('order_table.subscription_hint', {
                                number: order.subscription.number,
                            })}
                        </Card.Footer>
                    )}
                </Card>
            </Col>
        </Row>
    );
}

function Subscriptions() {
    const {t} = useTranslation('account');
    const {data, loading} = useQuery(queryGetUserSubscriptions);

    if (loading) {
        return <Loading text={t('subscriptions.loading')} />;
    }

    const subscriptionsUser = data?.user.subscriptions;
    // const subscriptionsCustomer = (data?.user.customer?.subscriptions || []).filter(({number}) => !subscriptionsUser.some(subscription => subscription.number === number))

    // <h2>Weitere Abonnements mit dieser Kundennummer</h2>
    // {subscriptionsCustomer.map(subscription => <Subscription key={subscription.number} subscription={subscription}/>)}

    return (
        <div>
            <h2>{t('subscriptions.title')}</h2>
            {subscriptionsUser.map((subscription) => (
                <Subscription
                    key={subscription.number}
                    subscription={subscription}
                />
            ))}
            {subscriptionsUser.length === 0 && (
                <div className="text-muted">{t('subscriptions.empty')}</div>
            )}
        </div>
    );
}

export function Subscription({subscription}) {
    const {t} = useTranslation('account');
    const validTo = day(subscription.validTo);
    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header className="border-bottom-0">
                        <Row className="mb-0">
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('subscription_table.number')}
                                </small>
                                <br />
                                {subscription.number}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('subscription_table.date')}
                                </small>
                                <br />
                                {day(subscription.createdAt).format(
                                    'DD.MM.YYYY'
                                )}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('subscription_table.valid_from')}
                                </small>
                                <br />
                                {day(subscription.validFrom).format(
                                    'DD.MM.YYYY'
                                )}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('subscription_table.valid_to')}
                                </small>
                                <br />
                                {validTo.diff(day(), 'year') > 10
                                    ? '-'
                                    : validTo.format('DD.MM.YYYY')}
                            </Col>
                            <Col>
                                <small className="text-muted text-uppercase">
                                    {t('subscription_table.purchase_number')}
                                </small>
                                <br />
                                {subscription.purchaseNumber || '-'}
                            </Col>
                        </Row>
                        {validTo.toDate() < NOW && (
                            <Row className="mb-0">
                                <Col>Dieses Abonnement ist abgelaufen</Col>
                            </Row>
                        )}
                    </Card.Header>
                    <Positions
                        positions={subscription.positions}
                        subscription
                    />
                </Card>
            </Col>
        </Row>
    );
}

export function ActivateLicense({code, licenses, refetchQueries}) {
    const {t} = useTranslation('account');
    const methods = useForm();
    const {watch, handleSubmit} = methods;
    const license = watch('license');

    const [
        doActivateLicense,
        {
            loading,
            //error
        },
    ] = useMutation(mutationActivateLicense, {
        onError: (error) => console.log(error),
        onCompleted: (data) => {
            if (data.activateLicense.user) {
                redirect(getEntityUrl(data.activateLicense.product));
            }
        },
        refetchQueries,
    });

    const makeSubmitHandler = (mode) =>
        handleSubmit(({license}) => {
            const [, number, item] = license.value.split('-');
            doActivateLicense({
                variables: {
                    input: {
                        mode,
                        number,
                        item,
                    },
                },
            });
        });

    const onActivateSubmit = makeSubmitHandler('USER');
    const onCodeSubmit = makeSubmitHandler('CODE');

    const codeActivationEnabled = code && license?.license.isOwner;

    return (
        <FormProvider {...methods}>
            <div className="card vds-card mb-4">
                <div className="card-body">
                    <h2 className="text-white">{t('licenses.activate_license.title')}</h2>
                    <div className="mb-2">
                        <SelectLicense
                            large
                            outerClassName="mb-0"
                            name="license"
                            licenses={licenses}
                            placeholder={t('licenses.activate_license.placeholder')}
                            rules={{
                                required: t('licenses.activate_license.rule'),
                            }}
                        />
                    </div>
                    <div>
                        {!codeActivationEnabled && (
                            <Button
                                block
                                size="lg"
                                variant="light"
                                type="submit"
                                name="mode"
                                value="USER"
                                onClick={() => onActivateSubmit()}
                                disabled={loading}
                            >
                                {t('licenses.activate_license.action_button_activate')}
                            </Button>
                        )}
                        {codeActivationEnabled && (
                            <ButtonGroup size="lg" className="d-flex">
                                <Button
                                    className="w100"
                                    variant="light"
                                    onClick={() => onActivateSubmit()}
                                    disabled={loading}
                                >
                                    {t('licenses.activate_license.action_button_activate')}
                                </Button>
                                <Button
                                    className="w100"
                                    variant="light"
                                    onClick={() => onCodeSubmit()}
                                    disabled={loading}
                                >
                                    {t('licenses.activate_license.action_button_code')}
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                </div>
            </div>
        </FormProvider>
    );
}

/*
function LicenseHelp()
{
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <Alert variant="warning" onClose={() => setShow(false)} dismissible>
      <Alert.Heading>Lizenzen erwerben</Alert.Heading>
      <p>Sie können Lizenzen zu digitalen VdS-Publikationen, VdS-Sammelwerken oder VdS-Services erwerben, indem Sie beim Kauf oder Abonnement eines Artikels den Bestellweg <i>Download</i> bzw. <i>Service</i> wählen. Beachten Sie bitte, dass nicht alle Artikel digital oder als Abonnment zur Verfügung stehen.</p>
      <p>Das Benutzerkonto mit dem Sie den Artikel bestellen gilt automatisch als <i>Eigentümer</i> der erworbenen Lizenzen. Diese finden Sie in der Liste <i>Ihre Lizenzen</i>.</p>
      
      <Alert.Heading>Gültigkeit von Lizenzen</Alert.Heading>
      <p>Lizenzen, die aus einem Auftrag hervorgehen, gelten ausschließlich für die gekaufte Auflage des Artikels</p>

      <Alert.Heading>Lizenzen aktivieren</Alert.Heading>


    </Alert>
  );
}
*/

function Licenses() {
    const {t} = useTranslation('account');
    const {data, loading} = useQuery(queryGetUserLicenses);

    const [
        doActivateLicenseCode,
        {
            loading: loadingActivateLicenseCode,
            //error: errorActivateLicenseCode
        },
    ] = useMutation(mutationActivateLicenseCode, {
        onError: (error) => console.log(error),
        onCompleted: (data) =>
            redirect(getEntityUrl(data.activateLicenseCode.product)),
        refetchQueries: [{query: queryGetUserLicenses}],
    });

    const handleActivateLicenseCode = ({code}) => {
        doActivateLicenseCode({
            variables: {
                input: {
                    code,
                },
            },
        });
    };

    if (loading && !data) {
        return <Loading text={t('licenses.loading')} />;
    }

    const licenses = data?.user?.licenses || [];
    const checkouts = data?.user?.checkouts || [];

    const ownedLicenses = licenses.filter(({isOwner}) => isOwner);

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <Form onSubmit={handleActivateLicenseCode}>
                        <div className="card vds-card mb-4">
                            <div className="card-body">
                                <h2 className="text-white">
                                    {t('licenses.redeem_license_code.title')}
                                </h2>
                                <div className="mb-2">
                                    <Input
                                        name="code"
                                        size="lg"
                                        outerClassName="mb-0"
                                        placeholder={t(
                                            'licenses.redeem_license_code.placeholder'
                                        )}
                                        rules={{
                                            required: t(
                                                'licenses.redeem_license_code.rule'
                                            ),
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        block
                                        size="lg"
                                        variant="light"
                                        type="submit"
                                        disabled={loadingActivateLicenseCode}
                                    >
                                        {t('licenses.redeem_license_code.action_button')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="col-md-6">
                    <ActivateLicense
                        code
                        licenses={licenses}
                        refetchQueries={[{query: queryGetUserLicenses}]}
                    />
                </div>
            </div>
            {/*
      <div className="row">
        <div className="col">
          <LicenseHelp />
        </div>
      </div>
      */}
            <div className="row">
                <div className="col-md-6">
                    <h2>{t('licenses.your_licenses.title')}</h2>
                    {ownedLicenses.length === 0 && (
                        <div className="text-muted">
                            {t('licenses.your_licenses.empty')}
                        </div>
                    )}
                    {ownedLicenses.map((license) => (
                        <License key={license.id} license={license} />
                    ))}
                </div>
                <div className="col-md-6">
                    <h2>{t('licenses.active_licenses.title')}</h2>
                    {checkouts.length === 0 && (
                        <div className="text-muted">
                            {t('licenses.active_licenses.empty')}
                        </div>
                    )}
                    {checkouts.map((checkout) => (
                        <UserCheckout key={checkout.id} checkout={checkout} />
                    ))}
                </div>
            </div>
        </>
    );
}

function UserCheckout({checkout}) {
    const {t} = useTranslation('account');
    const {product, code, activatedAt, actionUrl} = checkout;

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <Row className="mb-0">
                            <div className="col-12">
                                <small className="text-muted text-uppercase">
                                    {t('user_checkout.article')}
                                </small>
                                <br />
                                <EntityLink to={product}>
                                    {product.name} - {product.title}
                                </EntityLink>
                            </div>
                            <div className="col-6">
                                <small className="text-muted text-uppercase">
                                    {t('user_checkout.activated_at')}
                                </small>
                                <br />
                                {day(activatedAt).format('DD.MM.YYYY')}
                            </div>
                            <div className="col-6">
                                <small className="text-muted text-uppercase">
                                    {t('user_checkout.license_code')}
                                </small>
                                <br />
                                {code}
                            </div>
                        </Row>
                        {actionUrl && (
                            <Row className="mb-0 mt-2">
                                <div className="col">
                                    <a
                                        target="_blanc"
                                        href={actionUrl}
                                        title={t(
                                            'user_checkout.start_service'
                                        )}
                                        className="btn btn-light btn-block"
                                    >
                                        {t(
                                            'user_checkout.start_service'
                                        )}
                                    </a>
                                </div>
                            </Row>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function License({license}) {
    const {t} = useTranslation('account');
    const {available, quantity, checkouts, product, validTo, validFrom} =
        license;

    if (!product) {
        return null;
    }

    const validFromDate = day(validFrom);
    const validToDate = validTo && day(validTo);
    const {releaseDate} = product || {};
    const isExpired = () => {
        // Publication
        if (releaseDate) {
            if (
                validFromDate.isBefore(releaseDate) &&
                (!validToDate || validToDate.isBefore(releaseDate))
            ) {
                return 0;
            }

            return 2;
        }

        // Collection
        return validToDate && validToDate.isBefore(day()) ? 1 : 2;
    };

    const renderValidity = () => {
        if (!validTo) {
            return t('license.valid_at', {
                from: validFromDate.format('DD.MM.YYYY'),
            });
        }

        return validToDate.isAfter(day().add(1, 'year'))
            ? t('license.valid_from', {
                  from: validFromDate.format('DD.MM.YYYY'),
              })
            : t('license.valid_from_to', {
                  from: validFromDate.format('DD.MM.YYYY'),
                  to: validToDate.format('DD.MM.YYYY'),
              });
    };

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Row className="mb-0">
                            <div className="col-12">
                                <small className="text-muted text-uppercase">
                                    {t('license.article')}
                                </small>
                                <br />
                                <EntityLink to={product}>
                                    {product.name} - {product.title}
                                </EntityLink>
                            </div>
                            <div className="col-6">
                                <small className="text-muted text-uppercase">
                                    {t('license.availability')}
                                </small>
                                <br />
                                <span
                                    className={cn({
                                        'text-success': available > 0,
                                        'text-danger': available === 0,
                                    })}
                                >
                                    {available} {t('license.of')}{' '}
                                    {quantity}
                                </span>
                            </div>
                            <div className="col-6">
                                <small className="text-muted text-uppercase">
                                    {t('license.validity')}
                                </small>
                                <br />
                                <span
                                    className={cn({
                                        'text-success': isExpired() === 2,
                                        'text-warning': isExpired() === 1,
                                        'text-danger': isExpired() === 0,
                                    })}
                                >
                                    {renderValidity()}
                                </span>
                            </div>
                        </Row>
                    </Card.Header>
                    <Row className="mb-0">
                        <div className="col">
                            <LicenseCheckouts checkouts={checkouts} />
                        </div>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

function LicenseCheckouts({checkouts}) {
    const {t} = useTranslation('account');

    if (!checkouts.length) {
        return (
            <Card.Body>
                {t('license_checkouts.empty')}
            </Card.Body>
        );
    }

    const renderName = (user) => {
        if (!user) {
            return <i>{t('license_checkouts.unused')}</i>;
        }

        return `${user.userFirstname} ${user.userLastname}`;
    };

    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">{t('license_checkouts.code')}</th>
                    <th scope="col">{t('license_checkouts.user')}</th>
                </tr>
            </thead>
            <tbody>
                {checkouts.map((checkout) => (
                    <tr>
                        <td>
                            <code>{checkout.code}</code>
                        </td>
                        <td>{renderName(checkout.user)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function CategoryPin({pin, unpin, unpinning}) {
    const {t} = useTranslation('account');
    const {category} = pin;
    const onClick = () =>
        unpin({
            variables: {
                id: pin.id,
            },
        });

    return (
        <div className="list-group-item d-flex justify-content-between align-items-center">
            {category.name}
            <Button
                variant="light"
                size="sm"
                onClick={onClick}
                disabled={unpinning}
            >
                {t('pinboard.unpin')}
            </Button>
        </div>
    );
}

function MaterialPin({pin, unpin, unpinning}) {
    const {t} = useTranslation('account');
    const {material} = pin;
    const onClick = () =>
        unpin({
            variables: {
                id: pin.id,
            },
        });

    return (
        <div className="list-group-item d-flex justify-content-between align-items-center">
            {material.product.name}
            <Button
                variant="light"
                size="sm"
                onClick={onClick}
                disabled={unpinning}
            >
                {t('pinboard.unpin')}
            </Button>
        </div>
    );
}

function Pinboard() {
    const {t} = useTranslation('account');
    const {data, loading} = useQuery(queryGetUserPinboard);
    const [unpin, {loading: unpinning}] = useMutation(
        mutationRemovePinFromPinboard,
        {
            update(cache, {data}) {
                const normalizedId = cache.identify(data.pinboardRemovePin);
                cache.evict({id: normalizedId});
                cache.gc();
            },
        }
    );

    const pins = data?.user.pins || [];

    if (loading && !data) {
        return <Loading text={t('pinboard.loading')} />;
    }

    const [materialPins, categoryPins] = partition(
        pins,
        (pin) => !!pin.material
    );

    return (
        <div>
            <div>
                <h2>{t('pinboard.title')}</h2>
                <p>{t('pinboard.description')}</p>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h3>{t('pinboard.pinned_categories.title')}</h3>
                    <div className="list-group">
                        {categoryPins.map((pin) => (
                            <CategoryPin
                                key={pin.id}
                                pin={pin}
                                unpin={unpin}
                                unpinning={unpinning}
                            />
                        ))}
                        {categoryPins.length === 0 && (
                            <div className="list-group-item">
                                <i>{t('pinboard.pinned_categories.empty')}</i>
                            </div>
                        )}
                    </div>
                </div>
                <div class="col-md-6">
                    <h3>{t('pinboard.pinned_materials.title')}</h3>
                    <div className="list-group">
                        {materialPins.map((pin) => (
                            <MaterialPin
                                key={pin.id}
                                pin={pin}
                                unpin={unpin}
                                unpinning={unpinning}
                            />
                        ))}
                        {materialPins.length === 0 && (
                            <div className="list-group-item">
                                <i>{t('pinboard.pinned_materials.empty')}</i>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function Account(props) {
    const {t} = useTranslation('account');

    const user = useUser();
    const {path, url} = useRouteMatch();

    if (!user) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Keyvisual />
            <section id="content" className="container-fluid">
                <BreadCrumbs />
                <Row>
                    <Col>
                        <h1>{t('title')}</h1>
                        <UserCard />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Nav fill variant="tabs">
                            <Nav.Item>
                                <Nav.Link
                                    as={NavLink}
                                    to={`${url}/bestellungen`}
                                >
                                    {t('navigation.orders')}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    as={NavLink}
                                    to={`${url}/abonnements`}
                                >
                                    {t('navigation.subscriptions')}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${url}/lizenzen`}>
                                    {t('navigation.licenses')}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to={`${url}/infodienst`}>
                                    {t('navigation.pinboard')}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Switch>
                            <Route path={`${path}/bestellungen`}>
                                <Orders />
                            </Route>
                            <Route path={`${path}/abonnements`}>
                                <Subscriptions />
                            </Route>
                            <Route path={`${path}/lizenzen`}>
                                <Licenses />
                            </Route>
                            <Route path={`${path}/infodienst`}>
                                <Pinboard />
                            </Route>
                            <Route path={path}>
                                <Redirect to={`${url}/bestellungen`} />
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </section>
        </>
    );
}

import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';

i18next.use(HttpApi).init({
	interpolation: {escapeValue: false}, // React already does escaping
	lng: 'de', // default language to use
	fallbackLng: 'de',
	debug: process.env.NODE_ENV !== 'production',
});

export {i18next};

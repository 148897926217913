import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from '../common/Links';
import Fuse from 'fuse.js';
import Loading from 'common/Loading';
import Keyvisual from 'common/Keyvisual';
import {PublicationList} from 'common/CategoryProductList';
import {useQuery, useSearch, useTranslation} from 'hooks';
import {queryGetSearch} from 'graph';

export default function Search() {
  const {t} = useTranslation('search');
  const {data, loading} = useQuery(queryGetSearch);
  const keyword = useSearch('keyword', '');

  if (loading) {
    return <Loading text={t('loading')} />;
  }

  const publications = data?.publications || [];
  const collections  = data?.collections || [];
  const products = [...publications, ...collections];

  const fuse = new Fuse(products, {
    keys: [
      { name: 'number', weight: 3 },
      { name: 'name', weight: 2 },
      'title'
    ]
  });

  const result = fuse.search(keyword, { limit: 20 }).map(({item}) => item);

  return (
    <>
      <Keyvisual/>
      <Container as="section">
        <Row className="breadcrumb-wrapper">
          <Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" title={t('breadcrumb_home')}>
                    <span>{t('breadcrumb_home')}</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a href="#search" onClick={e => e.preventDefault()}>
                    <span>{t('breadcrumb_search')}</span>
                  </a>
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <header>
              <h4>{t('search_result_title')}</h4>
              <h1 dangerouslySetInnerHTML={{ __html: t('search_result_message', { keyword }) }} />
            </header>
          </Col>
        </Row>
        <Row>
          <Col>
            {result.length === 0 && (
              <p className="lead" dangerouslySetInnerHTML={{ __html: t('no_results', { keyword }) }} />
            )}
            {result.length > 0 && (
              <PublicationList 
                placeholder={t('placeholder')}
                publications={result} 
                loading={false}
                disableFilter
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React from 'react'
import {Link} from 'common/Links'
import Keyvisual from '../common/Keyvisual'
import {Mail, Phone} from 'react-feather'
import {useTranslation, Trans} from 'react-i18next';
import keyvisualImg from 'img/keyvisual_contact.jpg'

const BreadCrumbs = () => {
  const {t} = useTranslation('common');
  return (
    <div className="row breadcrumb-wrapper">
      <div className="col-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" title={t('navigation.home')}>
                <span>{t('navigation.home')}</span>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/kontakt" title={t('navigation.contact')}>
                <span>{t('navigation.contact')}</span>
              </Link>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  )
}


export default function Contact() {
  const {t} = useTranslation('contact')
  return (
    <>
      <Keyvisual
        src={keyvisualImg}
        title="Kontakt"
        alt="Kontakt"
      />
      <section id="content" className="container-fluid">
        <BreadCrumbs/>
        <div className="row">
          <div className="col">
            <h1>{t('title')}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card vds-card">
              <div className="card-body text-white">
                <Trans t={t} i18nKey="mail">
                  <h5>Schreiben Sie uns: verlag@vds.de</h5>
                  <p>Wir beraten Sie gerne.</p>
                </Trans>
                <Mail size={30}/>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card vds-card">
              <div className="card-body text-white">
                <Trans t={t} i18nKey="hotline">
                  <h5>Rufen Sie an: +49 (0)221 77 66 122</h5>
                  <p>Wir beraten Sie gerne persönlich von Montag bis Freitag 9 - 15 Uhr</p>
                </Trans>
                <Phone size={30}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
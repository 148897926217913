import React from 'react'
import {Link, Redirect} from 'common/Links'
import {DownloadIcon, CollectionIcon, PublicationVersionIcon} from 'common/Icons'
import Keyvisual from 'common/Keyvisual'
import AddToBasketModal from 'common/AddToBasketModal'
import RequestLoginModal from 'common/RequestLoginModal';
import Button, {BasketButton} from 'common/Button'
import Loading from 'common/Loading'
import PriceAndSubscriptionAlert from 'common/PriceAndSubscriptionAlert'
import StateAlert from 'common/StateAlert'
import LicenseAlert from 'common/LicenseAlert'
import DraftAvailableAlert from 'common/DraftAvailableAlert'
import {Bookmark as PinboardIcon} from 'react-feather';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation, Trans} from 'react-i18next';
import {
	useQuery,
	useMutation,
	useParams,
	useState,
	usePageTitle,
	useLocation,
	useUser,
} from 'hooks'
import {
	queryGetPublicationDetail,
	mutationAddMaterialToPinboard,
	mutationRemovePinFromPinboard,
} from 'graph'
import {cn, fullPublicationId} from 'utils'

import placeholderCover from 'img/placeholder-cover.png'
import blueArrowDown    from '../../.vds/Public/Icons/Pfeile/Pfeil_Hellblau_Nach_Unten_Boxen.svg'
import blueArrowUp      from '../../.vds/Public/Icons/Pfeile/Pfeil_Hellblau_Nach_Oben_Boxen.svg'
import quickLinkIcon    from '../../.vds/Public/Icons/Sonstige/VdS-Webseite-Quicklinks-Icon.svg'

function BreadCrumbs({publication})
{
	const {title, number} = publication
	const {t} = useTranslation('common')
	return (
		<div className="row breadcrumb-wrapper">
			<div className="col-12">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/" title="Startseite">
								<span>{t('navigation.home')}</span>
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/publikation/${number}`}>
								<span>{title}</span>
							</Link>
						</li>
					</ol>
				</nav>
			</div>
		</div>
	)
}

function PinButtonDisabled()
{
	const {t} = useTranslation('common')
	const title = t('buttons.add_to_pinboard');
	const [showLogin, setShowLogin] = useState(false);

	function onClick(event) {
		event.preventDefault();
		setShowLogin(true);
	}

	return (
		<>
			<RequestLoginModal
				show={showLogin}
				handleClose={e => setShowLogin(false)}
			/>
			<div id="box-anchor" className="mt-2">
				<a
					onClick={onClick}
					href="#add-to-pinboard"
					title={title}
				>
					<div className="row">
						<div className="col">
							<span>{title}</span>
						</div>
						<div className="col-auto">
							<PinboardIcon />
						</div>
					</div>
				</a>
			</div>
		</>
	);
}

function PinButtonActive({publication}) {
	const {t} = useTranslation('common')
	const [pin, {loading: pinning}] = useMutation(
		mutationAddMaterialToPinboard,
		{
			variables: {
				input: {
					material: {
						number: publication.material?.number,
					},
				},
			},
		}
	);
	const [unpin, {loading: unpinning}] = useMutation(
		mutationRemovePinFromPinboard,
		{
			variables: {
				id: publication.pin?.id,
			},
			update(cache, {data}) {
				const normalizedId = cache.identify(data.pinboardRemovePin);
				cache.evict({id: normalizedId});
				cache.gc();
			},
		}
	);

	const isPinned = !!publication.pin;

	function toggle(event) {
		event.preventDefault();

		if (pinning || unpinning) {
			return;
		}

		if (isPinned) {
			unpin();
		} else {
			pin();
		}
	}

	const title = isPinned ? t('buttons.remove_from_pinboard') : t('buttons.add_to_pinboard');

	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip>
					{isPinned && (
						<Trans t={t} i18nKey="buttons.tooltip.pinned">
							<h6>
								<strong>
									Kostenlosen Infodienst für diesen Artikel kündigen.
								</strong>
							</h6>
							<p className="mb-0">
								Sie erhalten keine weiteren Nachrichten zu neuen Auflagen.
							</p>
						</Trans>
					)}
					{!isPinned && (
						<Trans t={t} i18nKey="buttons.tooltip.unpinned">
							<h6>
								<strong>
									Kostenlosen Infodienst für diesen Artikel abonnieren.
								</strong>
							</h6>
							<p className="mb-0">
								Wir benachrichtigen Sie per E-Mail, wenn eine neue Auflage erscheint.
							</p>
						</Trans>
					)}
				</Tooltip>
			}
		>
			<div id="box-anchor" className="mt-2">
				<a
					className={cn({'text-success': isPinned})}
					onClick={toggle}
					href={
						isPinned ? '#unpin-from-pinboard' : '#pin-to-pinboard'
					}
					title={title}
				>
					<div className="row">
						<div className="col">
							<span>{title}</span>
						</div>
						<div className="col-auto">
							<PinboardIcon />
						</div>
					</div>
				</a>
			</div>
		</OverlayTrigger>
	);
}

function PinButton({publication})
{
	const user = useUser();

	if (user) {
		return (
			<PinButtonActive publication={publication} />
		)
	}

	return (
		<PinButtonDisabled />
	)
}

function DownloadButton({publication})
{
	const {actionUrl, actionLicenseLevel, orderMode} = publication
	const {t} = useTranslation('common')

	if (!actionUrl) {
		return null
	}

	const LICENSE_LEVEL_MAP = {
		NONE: t('buttons.license_free'),
		FULL: t('buttons.licensed'),
	}
	
	return (
		<Button
			as="a"
			className={cn(
				'h-100', {
					green: actionLicenseLevel === 'FULL'
				}
			)}
			target="_blanc"
			href={actionUrl}
			icon={<DownloadIcon className="vds-button-img" size={28}/>}
		>
			{orderMode === 'SERVICE' ? t('buttons.start_service') : t('buttons.download')}<br/>
			<small className="mt-n2">{LICENSE_LEVEL_MAP[actionLicenseLevel]}</small>
		</Button>
	)
}

function MainHeader({title})
{
	return (
		<div className="frame-default frame-type-header frame-layout-0">
			<header>
				<h1>{title}</h1>
			</header>
		</div>
	)
}

function PreHeader({product})
{
	return (
		<div className="frame-default frame-type-header frame-layout-0">
			<header>
				<h4 className="font-weight-bold">{fullPublicationId(product)}</h4>
			</header>
		</div>
	)
}

const showBasketButton = ({orderMode}) => orderMode !== 'DISABLED' 
const showDownloadButton = ({actionUrl}) => !!actionUrl

function Body({id, name})
{
	const [showMoreVersions, setShowMoreVersions] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [quicklinksOpen, setQuicklinksOpen] = useState(false)
	const {t} = useTranslation('publication');

	const {data, loading} = useQuery(queryGetPublicationDetail, {
		variables: {
			id
		}
	})

	if (loading) {
		return <Loading text={`${name} wird geladen ...`}/>
	}
 
  const publication = data.publication
	const {description, files, versions, collections} = publication

	return (
		<>
			<div className="row">
				<div className="col-md-4">
					<img alt="Cover" className="img-fluid vds-cover" src={publication.cover?.url || placeholderCover}/>
					<PinButton publication={publication} />
				</div>
				<div className="col">
					<DraftAvailableAlert product={publication} />
					<div className="row">
						<div className="col">
							<StateAlert product={publication}/>
						</div>
					</div>
					<div className="publication-description my-3">
						<div dangerouslySetInnerHTML={{__html: description}} />
					</div>
					<div className="row">
						{showDownloadButton(publication) && (
							<div className="col-12 col-md-6">
								<DownloadButton
									publication={publication}
								/>
							</div>
						)}
						{showBasketButton(publication) && (
							<div className="col-12 col-md-6">
								<BasketButton
									product={publication}
									onClick={() => setShowModal(true)}
								/>
							</div>
						)}
					</div>
					<LicenseAlert 
						product={publication}
						refetchQueries={[{
							query: queryGetPublicationDetail,
							variables: {
								id
							}
						}]}
					/>
					<div className="row">
						<div className="col">
							<PriceAndSubscriptionAlert
								product={publication}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="vds-quicklinks-box" id="quicklinks-box">
				<div className="vds-quicklinks-box-header-fix d-none d-md-block">
					<h1>Quicklinks</h1>
					<img className="quicklinks-box-icon" src={quickLinkIcon} alt=""/>
				</div>
				<div className={cn('row', {mobileHide: !quicklinksOpen})}>
					<div className="col-12 d-block d-md-none">
						<div className={cn('vds-quicklinks-box-header', {closed: !quicklinksOpen, open: quicklinksOpen})}>
							Quicklinks
							<img className="quicklinks-box-icon" src={quickLinkIcon} alt=""/>
							<div className="vds-quicklinks-box-arrow" onClick={() => setQuicklinksOpen(!quicklinksOpen)}>
								<img className="closed" src={blueArrowDown} alt=""/>
								<img className="open" src={blueArrowUp} alt=""/>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4">
						<div className="vds-quicklinks-box-header">
							<h2>{t('quicklinks.collections')}</h2>
						</div>
						<div className="vds-quicklinks-box-line"/>
						<div className="vds-quicklinks-box-link-text mt-0">
							<div className="list-group list-group-flush">
								{collections.map(collection => (
									<div key={collection.id} className="list-group-item px-1 d-flex">
										<div className="mr-2">
											<CollectionIcon size={19}/>
										</div>
										<Link key={collection.id} to={`/sammelwerk/${collection.slugName}`}>
											<p className="mb-2">{collection.name} - {collection.title}</p>
										</Link>
									</div>
								))}
								{collections.length === 0 && (
									<div className="list-group-item disabled">{t('quicklinks.collections_miss')}</div>
								)}
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4">
						<div className="vds-quicklinks-box-header">
							<h2>{t('quicklinks.additional_downloads')}</h2>
						</div>
						<div className="vds-quicklinks-box-line"/>
						<div className="vds-quicklinks-box-link-text mt-0">
							<div className="list-group list-group-flush">
								{files.additionalFiles.map(file => (
									<div key={file.id} className="list-group-item px-1 d-flex">
										<div className="mr-2">
											<DownloadIcon size={19}/>
										</div>
										<a target="_blanc" href={file.url}>{file.name}</a>
									</div>
								))}
								{files.additionalFiles.length === 0 && (
									<div className="list-group-item disabled">{t('quicklinks.additional_downloads_miss')}</div>
								)}
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4">
						<div className="vds-quicklinks-box-header">
							<h2>{t('quicklinks.history')}</h2>
						</div>
						<div className="vds-quicklinks-box-line"/>
						<div className="vds-quicklinks-box-link-text mt-0">
							<div className="list-group list-group-flush">
							{(showMoreVersions ? versions : versions.slice(0,10)).map(publication => (
								<div key={publication.id} className={cn('list-group-item', 'px-1', 'd-flex', {'font-weight-bold': publication.id === id})}>
									<div className="mr-2">
										<PublicationVersionIcon size={19}/>
									</div>
									<Link key={publication.id} to={`/publikation/${publication.slugName}/${publication.id}`}>
										{fullPublicationId(publication)}
									</Link>
								</div>
							))}
							</div>
							{!showMoreVersions && versions.length > 10 && (
								<a href="#show-more" onClick={e => {e.preventDefault(); setShowMoreVersions(true)}}>{t('quicklinks.history_show_more')}</a>
							)}
						</div>
					</div>
				</div>
			</div>
			<AddToBasketModal item={{product: {id}}} show={showModal} handleClose={() => setShowModal(false)}/>
		</>
	)
}

export default function Publication(props) {
	const {id: paramId, number} = useParams()
	const location = useLocation()
	const {loading, data} = useQuery(queryGetPublicationDetail, {
		variables: {
			id: paramId || number
		},
		fetchPolicy: 'cache-and-network'
	})

	usePageTitle(
		(loading && !data) ? 'Lade ...' : data?.publication?.title
	)

	if (loading && !data) {
		return <Loading/>
	}

	const publication = data?.publication

	if (!publication) {
		return (
			<Redirect push to={`/seite-nicht-gefunden?requested_pathname=${encodeURI(location.pathname)}`} />
		)
	}

	const {id, title, name} = publication

	return (
		<>
			<Keyvisual title={title}/>
			<section id="content" className="container-fluid">
				<BreadCrumbs publication={publication}/>
				<div className="row">
					<div className="col-12 content">
						<div className="publication-header">
							<PreHeader
								product={publication}
							/>
							<MainHeader 
								name={name}
								title={title}
							/>
						</div>
						<Body id={id} name={name}/>
					</div>
				</div>
			</section>
		</>
	)
}
import day from 'dayjs'
import cn from 'classnames'
import history from 'services/history'

export {
	day,
	cn
}

const STATE_MAP = {
  DRAFT: 'ENTWURF',
  PLANED: 'GEPLANT'
}

export const publicationVersion = ({state, version, displayVersion, releaseDate}) => {
  if (!version) {
    return 'Stand ' + day().format('YYYY.MM.DD')
  }

  if (displayVersion) {
    return displayVersion
  }

  const datePart = releaseDate ? day(releaseDate).format('YYYY-MM') : 'n.n.'
  const versionPart = STATE_MAP[state] || version.toString().padStart(2, '0')
  return `${datePart} (${versionPart})`
}

export function fullPublicationId({name, ...rest})
{
  return `${name} : ${publicationVersion(rest)}`
}

export function calcPrice(prices, amount) {
	return prices.reduce((best, current) => current.amount <= amount ? current : best)
}

export function calcPriceFromDiscounts(price, discounts, quantity) {
  const bestDiscount = discounts.reduce((best, current) => current.quantity <= quantity ? current : best)
  return price * (100 - bestDiscount.discount) / 100
}

export function calcVat(total, vat) {
  if (total === 0 || vat === 0) {
    return '-'
  }

  return `${Math.round(vat / total * 100)} %`
}

export function formatPrice(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substring(0, j) + thousands : '') + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}

export function makeDownloadUrl(product, file)
{
  return `${process.env.REACT_APP_SHOP_URI}/download/${product.slugName}${file ? '/' + file.id : ''}`
}

export function isBuyable(product)
{
  const {orderMode} = product
  if (!orderMode) {
    return false
  }

  return orderMode === ''
}

export function redirect(url) {
  history.push(url)
}
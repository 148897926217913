import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {ControlledSelect} from './BaseSelect'
import PropTypes from 'prop-types'
import {cn} from 'utils'

export default function Select({
	id,
	name, 
	label, 
	help,
	rules,
	outerClassName,
	...props
}) {

	const {control, errors} = useFormContext()

	return (
		<div className={cn(outerClassName, 'form-group')}>
			{label && <label htmlFor={name}>{label}</label>}
			<ControlledSelect 
				{...props}	
				id={id} 
				name={name} 
				rules={rules} 
				control={control}
			/>
			<ErrorMessage name={name} errors={errors}>
				{({message}) => <div className="invalid-feedback d-block">{message}</div>}
			</ErrorMessage>
		</div>
	)
}

Select.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string
}
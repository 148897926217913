import React, { useContext } from 'react';
import ShopContext from './context/ShopContext';
import { Switch, Route } from 'react-router-dom';
import Loading from './components/common/LoadingShop';
import { useTranslation, Trans } from 'react-i18next';
import * as Sentry from '@sentry/react';

// Nav
import Navigation from './components/common/Navigation';

// Pages
import Home from './components/pages/Home';
import Search from './components/pages/Search';
import Basket from './components/pages/Basket';
import Category from './components/pages/Category';
import Branch from './components/pages/Branch';
import Publication from './components/pages/Publication';
import Collection from './components/pages/Collection';
import Account from './components/pages/Account';
import Contact from './components/pages/Contact';
import NotFound from './components/pages/NotFound';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

export default function Container(props) {
	const shop = useContext(ShopContext);
	const { t } = useTranslation('common');

	if (!shop) {
		return <Loading />;
	}

	return (
		<>
			<Navigation />
			{
				// eslint-disable-next-line
			}{' '}
			<main name="top" id="top">
				<Switch>
					<SentryRoute
						path="/:lang?/publikation/:number/:id?"
						component={Publication}
					/>
					{/* Lagecy links from old shop */}
					<SentryRoute
						path="/:lang?/produkt/:number"
						component={Publication}
					/>
					<SentryRoute
						path="/:lang?/sammelwerk/:number"
						component={Collection}
					/>
					<SentryRoute path="/:lang?/kategorie/*" component={Category} />
					<SentryRoute path="/:lang?/categories/*" component={Category} />
					<SentryRoute path="/:lang?/betriebsart/*" component={Branch} />
					<SentryRoute path="/:lang?/branch/*" component={Branch} />
					<SentryRoute path="/:lang?/warenkorb" component={Basket} />
					<SentryRoute path="/:lang?/kontakt" component={Contact} />
					<SentryRoute path="/:lang?/suche" component={Search} />
					<SentryRoute path="/:lang?/benutzerkonto" component={Account} />
					<SentryRoute path="/:lang(de|en)?" component={Home} />
					<SentryRoute component={NotFound} />
				</Switch>
			</main>
			<div className="nav-bg-helper" />
			<div className="prefooter">
				<div className="container-fluid d-none d-md-block">
					<span>
						<a href="#top">
							{t('buttons.top')}
							<svg
								version="1.1"
								id="Ebene_1"
								className="pfeil-icon-grey pfeil-blue-on-hover"
								x="0px"
								y="0px"
								viewBox="0 0 22.7 8.6"
							>
								<g>
									<polyline
										className="st0"
										points="22,7.9 11.4,0.6 0.6,7.9"
									></polyline>
								</g>
							</svg>
						</a>
					</span>
				</div>
			</div>
			<footer id="footer">
				<div className="container-fluid front-no-color1 padding-bottom-sm-lg">
					<div className="row">
						<div className="address-wrapper col order-2 order-md-1">
							<div
								className="margin-top-xs-sm clickable icon-append d-xs-block d-sm-block d-md-none"
								data-toggle="collapse"
								role="button"
								href="#address-wrapper"
								aria-expanded="false"
								aria-controls="address-wrapper"
							>
								{t('navigation.contact')}
							</div>
							<div
								className="row collapse d-md-flex"
								id="address-wrapper"
							>
								<div className="col-12 col-sm-8 col-lg-4">
									<Trans i18nKey="prefooter.hotline" t={t}>
										<strong>Hotline</strong>
										<br />
										Wir beraten Sie gerne persönlich von
										Montag bis Freitag 9 - 15 Uhr:
										<br />
										<a
											title="+49 (0)221 7766 122 anrufen"
											href="callto:+492217766122"
										>
											<u>Tel.: +49 (0)221 7766 122</u>
										</a>
										<br />
										Fax: +49 (0)221 7766 109 <br />
										<a
											title="verlag@vds.de schreiben"
											href="mailto:verlag@vds.de"
										>
											<u>E-Mail: verlag@vds.de</u>
										</a>
										<br />
										<br />
										<a
											title="vds.de"
											href="https://vds.de/"
											target="_blank"
											rel="noreferrer"
										>
											{' '}
											<u> VdS Schadenverhütung </u>
										</a>
										<br />
										<a
											title="vds.de/verlag"
											href="https://vds.de/de/verlag/"
											target="_blank"
											rel="noreferrer"
										>
											<u>VdS-Verlag</u>
										</a>
									</Trans>
								</div>
								<div className="col-12 col-sm-8 col-lg-4">
									<Trans t={t} i18nKey="prefooter.subscriptions">
										<strong>Abo-Service</strong> <br />
										Für die meisten Produkte im Shop besteht die
										Möglichkeit des Abonnements
										(Änderungsdienst).
									</Trans>
								</div>
								<div className="col-12 col-sm-8 col-lg-4">
									<Trans t={t} i18nKey="prefooter.advantages">
										<strong>Ihre Vorteile </strong> <br />
										Direkte Verfügbarkeit durch
										Dokumenten-Download oder schnellen Versand
										sowie bequeme Bezahlung.
										<br />
										<br />
										<strong>
											Sicher einkaufen und bezahlen
										</strong>{' '}
										<br />
										Eine verschlüsselte Verbindung und
										verschiedene Zahlungsmöglichkeiten - so ist
										Einkaufen sicher.
									</Trans>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<footer id="subfooter">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-4 back-no-color5 order-2 order-md-1 padding-top-xs-sm">
							<p>© VdS Schadenverhütung GmbH</p>
						</div>
						<div className="col-12 col-md-6 col-lg-8 order-1 order-md-2">
							<ul className="nav footer-menu front-no-color1">
								<li className="nav-item">
									<a
										className="nav-link"
										target="_blanc"
										href="https://vds.de/impressum"
									>
										{t('footer.imprint')}
									</a>
								</li>
								<li className="d-none d-md-block">|</li>
								<li className="nav-item">
									<a
										className="nav-link"
										target="_blanc"
										href="https://vds.de/agb"
									>
										{t('footer.conditions')}
									</a>
								</li>
								<li className="d-none d-md-block">|</li>
								<li className="nav-item">
									<a
										className="nav-link"
										target="_blanc"
										href="https://vds.de/datenschutz"
									>
										{t('footer.privacy')}
									</a>
								</li>
								<li className="d-none d-md-block">|</li>
								<li className="nav-item">
									<a
										className="nav-link"
										target="_blanc"
										href="https://vds.de/agb"
									>
										{t('footer.terms')}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
			<div className="afterfooter d-block d-md-none">
				<a href="#top">
					<svg
						version="1.1"
						className="pfeil-icon-white pfeil-blue-on-hover"
						x="0px"
						y="0px"
						viewBox="0 0 22.7 8.6"
					>
						<g>
							<polyline
								className="st0"
								points="22,7.9 11.4,0.6 0.6,7.9"
							></polyline>
						</g>
					</svg>
					<span>Top</span>
				</a>
			</div>
		</>
	);
}

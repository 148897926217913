import React from 'react'
import {Link, Redirect} from 'common/Links'
import {DownloadIcon} from 'common/Icons'
import Keyvisual from 'common/Keyvisual'
import {PublicationList} from 'common/CategoryProductList'
import AddToBasketModal from 'common/AddToBasketModal'
import Button, {BasketButton} from 'common/Button'
import Loading from 'common/Loading'
import PriceAndSubscriptionAlert from 'common/PriceAndSubscriptionAlert'
import LicenseAlert from 'common/LicenseAlert'
import {useTranslation, Trans} from 'react-i18next';

import {useQuery, useParams, useState} from 'hooks'
import {queryGetCollectionDetail} from 'graph'
import {cn} from 'utils'

function BreadCrumbs({collection})
{
	const {t} = useTranslation('common');
	const {title, number} = collection
	return (
		<div className="row breadcrumb-wrapper">
			<div className="col-12">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/" title={t('navigation.home')}>
								<span>{t('navigation.home')}</span>
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/sammelwerk/${number}`}>
								<span>{title}</span>
							</Link>
						</li>
					</ol>
				</nav>
			</div>
		</div>
	)
}

function DownloadButton({collection})
{
	const {t} = useTranslation('common');
	const {actionUrl, actionLicenseLevel} = collection

	if (!actionUrl) {
		return null;
	}

	const LICENSE_LEVEL_MAP = {
		NONE:    t('buttons.license_level.none'),
		PARTIAL: t('buttons.license_level.partial'),
		FULL:    t('buttons.license_level.full'),
	}

	return (
		<Button
			as="a"
			className={cn(
				'h-100', {
					yellow: actionLicenseLevel === 'PARTIAL', 
					green: actionLicenseLevel === 'FULL'
				}
			)}
			target="_blanc"
			href={actionUrl}
			icon={<DownloadIcon className="vds-button-img" size={28}/>}
		>
			{t('buttons.download')}<br/>
			<small className="mt-n2">{LICENSE_LEVEL_MAP[actionLicenseLevel]}</small>
		</Button>
	)
}

function MainHeader({title})
{
	return (
		<div className="frame-default frame-type-header frame-layout-0">
			<header>
				<h1>{title}</h1>
			</header>
		</div>
	)
}

function PreHeader({product})
{
	return (
		<div className="frame-default frame-type-header frame-layout-0">
			<header>
				<h4 className="font-weight-bold">{product.name}</h4>
			</header>
		</div>
	)
}

function Body({id, name})
{
	const {t} = useTranslation('publication');
	const [showModal, setShowModal] = useState(false)
	const {data, loading} = useQuery(queryGetCollectionDetail, {
		variables: {
			id
		}
	})

	if (loading) {
		return <Loading text={t('loading', {name})}/>
	}
 
  const collection = data.collection
	const {number, description, cover} = collection

	return (
		<>
			<div className="row">
				{cover && (
					<div className="col-md-4">
						<img alt="Cover" className="img-fluid vds-cover" src={`https://shop.vds.de/cover/${number}.jpg`}/>
					</div>
				)}
				<div className="col">
					<div className="collection-description my-3">
						<div dangerouslySetInnerHTML={{__html: description}} />
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<BasketButton
								product={collection}
								onClick={() => setShowModal(true)}
							/>
						</div>
						<div className="col-12 col-md-6">
							<DownloadButton
								collection={collection}
							/>
						</div>
					</div>
					<LicenseAlert
						product={collection}
						refetchQueries={[{
							query: queryGetCollectionDetail,
							variables: {
								id
							}
						}]}
					/>
					<div className="row">
						<div className="col">
							<PriceAndSubscriptionAlert
								product={collection}
							/>
						</div>
					</div>
				</div>
			</div>
			<AddToBasketModal item={{product: {id}}} show={showModal} handleClose={() => setShowModal(false)}/>
		</>
	)
}

export default function Collection(props) {
	const {t} = useTranslation('publication');
	const {id: paramId, number} = useParams()
	const {loading, data} = useQuery(queryGetCollectionDetail, {
		variables: {
			id: paramId || number
		}
	})

	if (loading) {
		return <Loading/>
	}

	const collection = data?.collection

	if (!collection) {
		return (
			<Redirect push to='/seite-nicht-gefunden' />
		)
	}

	const {id, title, name} = collection
	const count = collection.publications.length;

	return (
		<>
			<Keyvisual title={title}/>
			<section id="content" className="container-fluid">
				<BreadCrumbs collection={collection}/>
				<div className="row">
					<div className="col-12 content">
						<div className="collection-header">
							<PreHeader
								product={collection}
							/>
							<MainHeader 
								name={name}
								title={title}
							/>
						</div>
						<Body id={id} name={name}/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<header>
							<Trans t={t} i18nKey="collection.header" count={count}>
								<h4>Inhalt</h4>
								<h1>In diesem Sammelwerk sind folgende {{count}} Publikationen enthalten</h1>
							</Trans>
						</header>
						<PublicationList
							loading={loading}
							publications={collection.publications}
						/>
					</div>
				</div>
			</section>
		</>
	)
}
import React from 'react';
import {useUser} from '../../hooks';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const fullname = (_) =>
	`${_.userTitle || ''} ${_.userFirstname} ${_.userExtraname || ''} ${
		_.userLastname
	}`
		.trim()
		.replace(/ +(?= )/g, '');
const company = ({name, name2, name3, name4, street, zip, city}) => {
	const fullname = [name, name2, name3, name4]
		.filter(Boolean)
		.join(' ')
		.trim()
		.replace(/ +(?= )/g, '');
	const address = [street, zip, city]
		.filter(Boolean)
		.join(', ')
		.trim()
		.replace(/ +(?= )/g, '');
	return `${fullname}, ${address}`;
};

export default function UserCard() {
	const {t} = useTranslation('common')
	const user = useUser();

	if (!user) {
		return null;
	}

	return (
		<Card className="vds-card">
			<Card.Body>
				<Card.Title>
					{t('user_card.login')}:{' '}
					<strong>{fullname(user)}</strong> ({user.email})
					<br />
					{t('user_card.recipient')}:{' '}
					<strong>{company(user.companyAddress)}</strong>
					<br />
					{t('user_card.customer_no')}:{' '}
					<strong>
						{user.customer?.number || t('user_card.customer_no_unknown')}
					</strong>
				</Card.Title>
			</Card.Body>
		</Card>
	);
}

import { useContext } from 'react'
import ShopContext from '../context/ShopContext'

export default function useBranches() {
  const context = useContext(ShopContext)

  if (context === true) {
  	return null
  }

  return context.branches
}


import React from 'react'
import {useUser, useModalState} from '../../hooks'
import cn from 'classnames'
import RequestLoginModal from './RequestLoginModal'
import {BasketIcon} from 'common/Icons'
import {useTranslation} from 'react-i18next';
import {formatPrice} from 'utils'

export default function Button({children, as="button", className, icon, type='button', ...props}) {
	return (
		React.createElement(as, {type, className: cn('vds-button', 'mb-1', className), ...props},
			<div className="vds-button-text">
				<span className="d-block text-nowrap">{children}</span>
				{icon}
			</div>
		)
	)
}

export function RequiresLoginButton({onClick:innerOnClick, debug, ...props}) {
	const user = useUser()
	const [isOpen, open, close] = useModalState()

	if (user || debug) {
		return (
			<Button onClick={innerOnClick} {...props}/>
		)
	}

	return (
		<>
			<Button onClick={() => open()} {...props}/>		
			<RequestLoginModal show={isOpen} handleClose={close}/>
		</>
	)
}

export const INTERVAL_UNIT_MAP = {
	YEAR:  ({intervalCount}) => intervalCount > 1 ? `${intervalCount} Jahre` : 'Jahr',
	MONTH: ({intervalCount}) => intervalCount > 1 ? `${intervalCount} Monate` : 'Monat',
	WEEK:  ({intervalCount}) => intervalCount > 1 ? `${intervalCount} Wochen` : 'Woche'
}

/**
 * Generates the basket button label
 */
const basketButtonLabel = (t, mode) => {
	switch(mode) {
			case 'DISABLED':
				return t('buttons.buy');
			case 'ENABLED':
				return t('buttons.buy_or_subscribe');
			case 'FORCE_ENABLED':
				return t('buttons.subscribe');
			default:
				throw new Error('Unknown subscription mode ' + mode);
		}
}

export const subscriptionIntervalUnitLabel = (t, {intervalUnit, intervalCount: count}) => {
	switch(intervalUnit) {
			case 'YEAR':
				return t('buttons.year', {count});
			case 'MONTH':
				return t('buttons.month', {count});
			case 'WEEK':
				return t('buttons.week', {count});
			default:
				throw new Error('Unknown subscription plan unit ' + intervalUnit);
		}
}

export const orderDisabled = product => product.orderMode === 'DISABLED'

export function BasketButton({product, onClick})
{
	const {t} = useTranslation('common');
	const {subscriptionMode='DISABLED', subscriptionPlan, price} = product

	if (orderDisabled(product)) {
		return null
	}

	return (
		<Button 
			onClick={onClick}
			className="h-100"
			icon={<BasketIcon className="vds-button-img" size={28}/>}
		>
			{basketButtonLabel(t, subscriptionMode)}<br/>
			<>
				{subscriptionMode !== 'FORCE_ENABLED' && (
					<small className="d-block mt-n1">{t('buttons.single_price')} {price.priceFormated}</small>
				)}
				{subscriptionPlan && (
					<small className="d-block mt-n2">{t('buttons.subscription_price')} {formatPrice(subscriptionPlan.quantity * price.price * ((100 - subscriptionPlan.cycle.discount.value) / 100))}&nbsp;EUR {t('per')} {subscriptionIntervalUnitLabel(t, subscriptionPlan.cycle)}</small>
				)}
			</>
		</Button>
	)
}
import React from 'react'
import {useFormContext} from 'react-hook-form'

export default function Hidden({
	id,
	name, 
	...props
}) {
	const {register} = useFormContext()
	return (
		<input 
			ref={register} 
			type="hidden"
			name={name}
			id={id || name} 
			{...props}
		/>
	)	
}
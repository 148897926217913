import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get} from 'lodash'
import cn from 'classnames'

export default function SimpleSelect({
	id,
	name, 
	label, 
	help,
	rules,
	size,
	options,
	placeholder
}) {
	const {register, errors, readOnly} = useFormContext()
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			{help && <small className="form-text text-muted">{help}</small>}
			<select
				ref={register(rules)} 
				className={cn(
					'form-control', 
					{
						'is-invalid': get(errors, name),
						'form-control-lg': size === 'lg',
						'form-control-sm': size === 'sm'
					}
				)}
				name={name}
				id={id || name}
				readOnly={readOnly}
				defaultValue={placeholder && ''}
			>
				{placeholder && <option disabled value="">{placeholder}</option>}
				{options.map(o => (
					<option disabled={o.disabled} key={o.value} value={o.value}>{o.label}</option>
				))}
			</select>
			<ErrorMessage name={name} errors={errors}>
				{({ message }) => <div className="invalid-feedback">{message}</div>}
			</ErrorMessage>
		</div>
	)
}
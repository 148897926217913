import React from 'react'
import ReactSelect from 'react-select'
import { Controller } from 'react-hook-form'

const white = '#ffffff';
const bsBorderBlue = '#2C7BE5';
const bsBorderGrey = '#D2DDEC';
//const bsBackgroundGrey = '#eeeeee';
const bsBackgroundOption = '#f8f9fa';
const bsBackgroundOptionSelected = '#eeeeee';
const bsControlTextColor = '#495057';
const bsControlPlaceholderColor = '#6c757d';
const bsFontSize = '1rem'
const bsFontSizeLg = '1.25rem'
//const bsFontSizeSm = '0.875rem'

/*
height: calc(1.5em + 1rem + 2px);
padding: 0.5rem 1rem;
font-size: 1.25rem;
line-height: 1.5;
border-radius: 0.3rem;
*/

const styles = {
	control: (base, ...rest) => {
		return {
			...base,
			//height: 'calc(1.5em + 1rem + 2px)',
			//minHeight: 'calc(1.5em + 1rem + 2px)',
			backgroundColor: white,
			borderWidth: 0,
			boxShadow: 'none',
			//color: bsControlTextColor,
		}
	},
	valueContainer: (base, state) => ({
		...base,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: state.selectProps.menuIsOpen ? bsBorderBlue : bsBorderGrey,
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4,
		padding: state?.selectProps?.large ? '0.5rem 1rem' : '0.5rem 0.75rem',
		height: 'calc(1.5em + 1rem + 2px)',
		fontSize: state?.selectProps?.large ? bsFontSizeLg : bsFontSize,
	}),
	input: (base, input, state) => {
		return {
			...base,
			color: bsControlTextColor,
			marginTop: 0,
			marginBottom: 0,
		}
	},
	singleValue: base => ({
		...base,
		color: bsControlTextColor,
	}),
	placeholder: (base, state) => ({
		display: state.selectProps.menuIsOpen ? 'none' : 'inline',
		color: bsControlPlaceholderColor,
		paddingLeft: 3,
	}),
	indicatorSeparator: base => ({
		display: 'none',
	}),
	clearIndicator: base => ({
		...base,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderStyle: 'solid',
		borderColor: bsBorderGrey,
		backgroundColor: white,
		padding: '0.5rem 0.75rem',
		height: 'calc(1.5em + 1rem + 2px)',
		width: 42,
		color: bsControlTextColor,
		':hover': {
			color: bsControlTextColor,
		},
	}),
	dropdownIndicator: base => ({
		...base,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderStyle: 'solid',
		borderColor: bsBorderGrey,
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		backgroundColor: white,
		padding: '0.5rem 0.75rem',
		height: 'calc(1.5em + 1rem + 2px)',
		width: 42,
		color: bsControlTextColor,
		':hover': {
			color: bsControlTextColor,
		},
	}),
	loadingIndicator: base => ({
		...base,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderStyle: 'solid',
		borderColor: bsBorderGrey,
		backgroundColor: white,
		padding: '0.5rem 0.75rem',
		height: 40,
		width: 42,
	}),
	option: (base, state) => ({
		...base,
		color: bsControlTextColor,
		backgroundColor: state.isSelected
			? bsBackgroundOptionSelected
			: state.isFocused ? bsBackgroundOption : white,
	}),
}

export function Select({ options, value, defaultValue, getOptionValue, ...rest }) {
	// Ungroup if nessesary
	const flatOptions = options.reduce((flat, option) => ([...flat, ...(option.options || [option])]), [])
	const find = value => option => (getOptionValue ? getOptionValue(option) : option.value) === value

	return (
		<ReactSelect
			styles={styles}
			options={options}
			getOptionValue={getOptionValue}
			defaultValue={flatOptions.find(find(defaultValue))}
			value={flatOptions.find(find(value))}
			{...rest}
		/>
	)
}

export function ControlledSelect({ options, rules, onChange, control, name, placeholder, noOptionsMessage, ...props }) {
	const defaultOnChange = ([selected]) => {
		// React Select return object instead of value for selection
		return selected && selected.value
	}

	const defaultNoOptionsMessage = ({ inputValue }) => inputValue ? `Keine Ergebnisse für '${inputValue}'` : 'Die Liste ist leer'

	return (
		<Controller
			as={<Select
				options={options}
				placeholder={placeholder || 'Bitte wählen ...'}
				noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
				{...props}
			/>}
			name={name}
			control={control}
			rules={rules}

			onChange={onChange || defaultOnChange} />
	)
}


import React, {Suspense, useState, useEffect} from 'react';
import {ApolloProvider} from '@apollo/client';
import {ShopProvider} from './context/ShopContext';
import {Router as RouterProvider, withRouter, useRouteMatch} from 'react-router-dom';
import Loading from './components/common/LoadingShop';

import history from './services/history';
import apolloClient, {cache} from './services/client';

import {persistCache} from 'apollo-cache-persist';

import {I18nextProvider} from 'react-i18next';
import {i18next} from './services/translation';

import Container from './Container';

const ScrollHandler = withRouter(({location, children}) => {
    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash !== '') {
            const element = document.getElementById(hash);
            setTimeout(() => {
                window.scrollTo({
                    behavior: element ? 'smooth' : 'auto',
                    top: element ? element.offsetTop : 0,
                });
            }, 100);
        }
    }, [location]);

    return children;
});

function App() {
    const [client, setClient] = useState(undefined);

    useEffect(() => {
        if (window.localStorage && false) {
            persistCache({
                cache,
                storage: window.localStorage,
            }).then(() => setClient(apolloClient));
        } else {
            setClient(apolloClient);
        }
    }, []);

    // This keeps the backend session alive
    useEffect(() => {
        let heartbeat = window.setInterval(() => {
            fetch(process.env.REACT_APP_HEARTBEAT_URI || '/api/heartbeat');
        }, 60000);

        return () => window.clearInterval(heartbeat);
    }, [])

    if (!client) {
        return null;
    }

    return (
        <Suspense fallback={<Loading />}>
            <ApolloProvider client={client}>
                <RouterProvider history={history}>
                    <TranslatedApp />
                </RouterProvider>
            </ApolloProvider>
        </Suspense>
    );
}

function TranslatedApp()
{
    const match = useRouteMatch('/:lang(de|en)');

    if (match) {
        i18next.changeLanguage(match.params.lang);
    }

    return (
        <I18nextProvider i18n={i18next}>
            <ShopProvider>
                <ScrollHandler>
                    <Container />
                </ScrollHandler>
            </ShopProvider>
        </I18nextProvider>
    );
}

export default App;

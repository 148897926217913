import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {Link} from '../common/Links';
import Keyvisual from '../common/Keyvisual'
import UserCard from '../common/UserCard'
import Check from './basket/Check'
import Address from './basket/Address'
import Payment from './basket/Payment'
import Completed from './basket/Completed'
import cn from 'classnames'
import {useTranslation} from 'react-i18next';

const CHECK     = 0
const ADDRESS   = 1
const PAYMENT   = 2
const COMPLETED = 3

const STEP_CAPTIONS = {
	[CHECK]: 'steps.check',
	[ADDRESS]: 'steps.address',
	[PAYMENT]: 'steps.payment',
	[COMPLETED]: 'steps.complete',
}

const STEP_IDS = {
	[CHECK]: 'check',
	[ADDRESS]: 'address',
	[PAYMENT]: 'payment',
	[COMPLETED]: 'completed'
}

const DIRS = {
	[CHECK]: {next: ADDRESS},
	[ADDRESS]: {next: PAYMENT, prev: CHECK},
	[PAYMENT]: {next: COMPLETED, prev: ADDRESS}
}

const BreadCrumbs = () => {
	const {t} = useTranslation('common');
	return (
		<div className="row breadcrumb-wrapper">
			<div className="col-12">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/" title={t('navigation.basket')}>
								<span>{t('navigation.home')}</span>
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/warenkorb" title={t('navigation.basket')}>
								<span>{t('navigation.basket')}</span>
							</Link>
						</li>
					</ol>
				</nav>
			</div>
		</div>
	)
}

function ProgressItem({currentStep, id, step, children}) {
	const {t} = useTranslation('basket');
	return (
		<li className={cn({active: currentStep === step, done: currentStep > step})} id={STEP_IDS[step]}>
			<span className="d-none d-sm-inline">{t(STEP_CAPTIONS[step])}</span>
		</li>
	)
}

export default function Basket(props) {
	const {t} = useTranslation('basket');
	const [currentStep, setCurrentStep] = useState(CHECK)
	const [currentData, setCurrentData] = useState()

	const next = data => {
		setCurrentData(data)
		setCurrentStep(DIRS[currentStep]['next'])
	}
	const prev = () => setCurrentStep(DIRS[currentStep]['prev'])

	return (
		<>
			<Keyvisual/>
			<section id="content" className="container-fluid">
				<BreadCrumbs/>
				<Row>
					<Col>
						<h1>{t('title')} - {t(STEP_CAPTIONS[currentStep])}</h1>
						<UserCard/>
					</Col>
				</Row>
				<Row>
					<Col>
						<ul id="progressbar">
							<ProgressItem currentStep={currentStep} step={CHECK}/>
							<ProgressItem currentStep={currentStep} step={ADDRESS}/>
							<ProgressItem currentStep={currentStep} step={PAYMENT}/>
							<ProgressItem currentStep={currentStep} step={COMPLETED}/>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col>
						{({
							[CHECK]    : () => (<Check next={next}/>),
							[ADDRESS]  : () => (<Address next={next} prev={prev}/>),
							[PAYMENT]  : () => (<Payment next={next} prev={prev}/>),
							[COMPLETED]: () => (<Completed data={currentData}/>)
						}[currentStep])()}
					</Col>
				</Row>
			</section>
		</>
	)
}
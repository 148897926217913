import React from 'react'
import {Link, Redirect} from 'common/Links'
import {ArrowLeft} from 'common/Icons'
import Keyvisual from 'common/Keyvisual'
import {PublicationList} from 'common/CategoryProductList'

import {useBranches, useParams, useQuery, usePageTitle} from 'hooks'
import {queryGetPublicationListByBranch} from 'graph'

const BreadCrumb = ({id, name, slugPath, parent}) => {
	const branches = useBranches()
	return (
		<>
			{ parent && <BreadCrumb {... branches.findById(parent.id) } />}
			<li className="breadcrumb-item">
				{ slugPath ? (
					<Link to={`/betriebsart/${slugPath}`} title={name}>
						<span className="d-inline-block text-truncate" style={{maxWidth: 300}}>{name}</span>
					</Link>
				) : (
					<span>{name}</span>
				)}
			</li>
		</>
	)
}

const BreadCrumbs = ({branch}) => {
	return (
		<div className="row breadcrumb-wrapper">
			<div className="col-12">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/" title="Startseite">
								<span className="d-inline-block text-truncate">Startseite</span>
							</Link>
						</li>
						<BreadCrumb {... branch} />
					</ol>
				</nav>
			</div>
		</div>
	)
}

const SideSubNav = ({branches}) => (
	<>
		<img className="hassubicon" src="https:/vds.de/typo3conf/ext/vds_sitepackage/Resources/Public/Icons/Pfeile/Pfeil_Blau_Nach_Rechts.svg" alt="arrow_right"/>
		<ul className="nested nav flex-column">
			{branches.map(branch => (
				<li key={branch.id} className="nav-item">
					<Link to={`/betriebsart/${branch.slugPath}`} title={branch.title}>
						<span>{branch.name}</span>
					</Link>
				</li>
			))}
		</ul>
	</>
)

function SideNav({branch}) {
	const parent = useBranches().findById(branch.parent && branch.parent.id)
	return (
		<div className="d-none d-md-block col-md-3" style={{paddingRight: 40}}>
			{branch.children.length > 0 && (
				<ul className="nav subnavigation flex-column mb-4">
					{branch.children.map(branch => (
						<li key={branch.id} className="nav-item hassub">
							<Link to={`/betriebsart/${branch.slugPath}`} title={branch.title}>
								<span>{branch.name}</span>
							</Link>
							{branch.children.length > 0 && <SideSubNav branches={branch.children}/>}
						</li>
					))}
				</ul>
			)}
			{ parent && (
				<div id="box-anchor">
					<Link to={`/betriebsart/${parent.slugPath}`} title={parent.title}>
						<div className="row">
							<div className="col">
								<span>Aufwärts</span>
							</div>
							<div className="col-auto">
								<ArrowLeft/>
							</div>
						</div>
					</Link>
				</div>
			)}
		</div>
	)
}

export default function Branch(props) {
	const slugPath = useParams()[0]
	const branch = useBranches().findBySlugPath(slugPath)
	usePageTitle(branch?.name)

	const {data, loading} = useQuery(queryGetPublicationListByBranch, {
		variables: {
			branch: branch.id
		},
		skip: !branch
	})

	if (!branch) {
		return (
			<Redirect to='/seite-nicht-gefunden' />
		)
	}

	const publications = data?.publicationsByBranch || []

	return (
		<>
			<Keyvisual/>
			<section id="content" className="container-fluid">
				<BreadCrumbs branch={branch}/>
				<div className="row">
					<SideNav branch={branch}/>
					<div className="col-12 col-md-9 content">
						<header>
							<h4>Betriebsart</h4>
							<h1>{branch.name}</h1>
						</header>
						<PublicationList
							publications={publications}
							loading={loading}
						/>
					</div>
				</div>
			</section>
		</>
	)
}
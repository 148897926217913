import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get} from 'lodash'
import cn from 'classnames'

function InputGroup({size, renderPrepend, renderAppend, children})
{
	if (!renderPrepend && !renderAppend) {
		return children;
	}

	return (
		<div className="input-group">
			{renderPrepend && (
				<div className="input-group-prepend">
					{renderPrepend()}
				</div>
			)}
			{children}
			{renderAppend && (
				<div className="input-group-append">
					{renderAppend()}
				</div>
			)}
		</div>
	)
}

const Input = ({
	id,
	name, 
	label, 
	help,
	rules,
	size,
	outerClassName,
	type = 'text', 
	placeholder = '',
	renderPrepend,
	renderAppend,
	...props
}) => {
	const {register, errors, readOnly} = useFormContext()
	return (
		<div className={cn(outerClassName, 'form-group')}>
			{label && <label htmlFor={name} className={cn({'mb-0' : help})}>{label}</label>}
			{help && <small className="form-text text-muted">{help}</small>}
			<InputGroup
				renderPrepend={renderPrepend}
				renderAppend={renderAppend}
				size={size}
			>
				<input 
					ref={register(rules)} 
					className={cn(
						'form-control', 
						{
							'is-invalid': get(errors, name),
							'form-control-lg': size === 'lg',
							'form-control-sm': size === 'sm'
						}
					)}
					type={type} 
					name={name}
					id={id || name} 
					placeholder={placeholder}
					readOnly={readOnly}
					{...props}
				/>
			</InputGroup>
			<ErrorMessage name={name} errors={errors}>
				{({ message }) => <div className="invalid-feedback">{message}</div>}
			</ErrorMessage>
		</div>
	)	
}


export default Input
import React from 'react';
import {Button, Row, Col, Table} from 'react-bootstrap';
import AddToBasketModal from 'common/AddToBasketModal';
import VdSButton, {RequiresLoginButton} from 'common/Button';

import {Package, ArrowLeft} from 'react-feather';

import {mutationRemoveBasketItem} from 'graph';
import {useTranslation} from 'react-i18next';
import {useState, useMutation, useBasket, useHistory, useSearch} from 'hooks';
import {fullPublicationId, calcPrice, formatPrice} from 'utils';

const IconAddress = () => <Package className="vds-button-img" size={28} />;
const IconStartpage = () => <ArrowLeft className="vds-button-img" size={28} />;

const INTERVAL_UNIT_MAP = {
	YEAR: (t, {intervalCount}) =>
		t('check.subscription.intervals.year', {count: intervalCount}),
	MONTH: (t, {intervalCount}) =>
		t('check.subscription.intervals.month', {count: intervalCount}),
	WEEK: (t, {intervalCount}) =>
		t('check.subscription.intervals.week', {count: intervalCount}),
};

function Rabatt({normalPrice, price}) {
	if (normalPrice === 0) {
		return null;
	}

	const rabatt = Math.round((1 - price / normalPrice) * 100);

	if (rabatt === 0) {
		return null;
	}

	return (
		<div>
			(<i>{rabatt}%&nbsp;Rabatt</i>)
		</div>
	);
}

function ConfigShipping({item, plan, extraDownloadPrice}) {
	const {t} = useTranslation('basket');
	const price =
		extraDownloadPrice > 0
			? `+ ${formatPrice(extraDownloadPrice)} EUR`
			: t('check.extra_download.free');

	return (
		<ul>
			<li>
				{t('check.order_method.title')}{' '}
				<strong>{t('check.order_method.delivery')}</strong>
			</li>
			<li>
				{t('check.extra_download.title')}{' '}
				<strong>
					{item.extraDownload || item.product.number === 'S+S'
						? t('check.extra_download.yes', {price})
						: t('check.extra_download.no')}
				</strong>
			</li>
			<li>
				{t('check.subscription.title')}{' '}
				<strong>
					{item.subscription
						? plan
							? t('check.subscription.yes_plan', {
									plan: INTERVAL_UNIT_MAP[
										plan.cycle.intervalUnit
									](t, plan.cycle),
							  })
							: t('check.subscription.yes_release')
						: t('check.subscription.no')}
				</strong>
			</li>
		</ul>
	);
}

function ConfigDigital({item, plan}) {
	const {t} = useTranslation('basket');
	return (
		<ul>
			<li>
				{t('check.order_method.title')}{' '}
				<strong>{t('check.order_method.digital')}</strong>
			</li>
			<li>
				{t('check.subscription.title')}{' '}
				<strong>
					{item.subscription
						? plan
							? t('check.subscription.yes_plan', {
									plan: INTERVAL_UNIT_MAP[
										plan.cycle.intervalUnit
									](t, plan.cycle),
							  })
							: t('check.subscription.yes_release')
						: t('check.subscription.no')}
				</strong>
			</li>
		</ul>
	);
}

function BasketItem({item, setItem, removeItem}) {
	const {t} = useTranslation('basket');
	const {
		type,
		quantity,
		product,
		subscription,
		extraDownload,
		isBundle,
		discount: itemDiscount,
	} = item;
	const {price, subscriptionPlan: plan} = product;

	function calculateDiscount() {
		if (itemDiscount?.value > 0) {
			return (100 - itemDiscount.value) / 100;
		}

		if (subscription && plan) {
			return (100 - plan.cycle.discount.value) / 100;
		}

		return 1;
	}

	function calculateExtraDownloadDiscount() {
		switch (true) {
			case extraDownload && product.material.number === 'S+S':
				return 1; // 100%
			case extraDownload:
				return 0.8; // 80%
			default:
				return 1;
		}
	}

	const discount = calculateDiscount();
	const basePrice = price.price;
	const bestPrice = calcPrice(price.prices, quantity).price * discount;
	const extraDownloadPrice =
		basePrice * (1 - calculateExtraDownloadDiscount());
	const totalPrice = bestPrice * quantity + extraDownloadPrice;

	return (
		<tr>
			<td>
				<div>
					<h5>
						<small className="text-muted">
							{fullPublicationId(product)}
						</small>
						<br />
						{product.title}
					</h5>
				</div>
				<div>
					{type === 'SHIPPING' && (
						<ConfigShipping
							item={item}
							extraDownloadPrice={extraDownloadPrice}
							plan={plan}
						/>
					)}
					{type === 'DIGITAL' && (
						<ConfigDigital item={item} plan={plan} />
					)}
				</div>

				<div>
					{!isBundle && (
						<Button
							variant="outline-dark"
							onClick={() => setItem(item)}
						>
							{t('check.edit_position')}
						</Button>
					)}{' '}
					<Button
						variant="outline-dark"
						onClick={() => removeItem(item)}
					>
						{isBundle
							? t('check.remove_bundle')
							: t('check.remove_position')}
					</Button>
				</div>
			</td>
			<td>{quantity}</td>
			<td>
				<div>{formatPrice(bestPrice)} EUR</div>
				<Rabatt normalPrice={basePrice} price={bestPrice} />
			</td>
			<td>{formatPrice(totalPrice)} EUR</td>
		</tr>
	);
}

export default function Check({next}) {
	const {t} = useTranslation('basket');
	const {push} = useHistory();
	const basket = useBasket();
	const returnTo = useSearch('return_to');
	const [item, setItem] = useState(null);
	const empty = basket.items.length === 0;
	const [removeItem] = useMutation(mutationRemoveBasketItem);

	return (
		<div>
			{!empty && (
				<Table hover>
					<thead>
						<tr>
							<th>{t('check.table.article')}</th>
							<th>{t('check.table.quantity')}</th>
							<th>{t('check.table.single_price')}</th>
							<th>{t('check.table.total')}</th>
						</tr>
					</thead>
					<tbody>
						{basket.items.map((i) => (
							<BasketItem
								key={i.id}
								item={i}
								setItem={setItem}
								removeItem={(item) =>
									removeItem({variables: {id: item.id}})
								}
							/>
						))}
					</tbody>
				</Table>
			)}
			{empty && (
				<div>
					<h2>{t('check.empty.title')}</h2>
					<p className="lead mb-4">{t('check.empty.description')}</p>
				</div>
			)}
			<Row>
				<Col>
					<VdSButton
						onClick={() => push(returnTo || '/')}
						icon={<IconStartpage />}
					>
						{returnTo ? t('check.prev') : t('check.prev_home')}
					</VdSButton>
				</Col>
				<Col>
					<RequiresLoginButton
						disabled={empty}
						onClick={next}
						icon={<IconAddress />}
					>
						{t('check.next')}
					</RequiresLoginButton>
				</Col>
			</Row>
			<AddToBasketModal
				item={item}
				show={!!item}
				handleClose={() => setItem(null)}
			/>
		</div>
	);
}

import React from 'react'
import Select from './Select'
import {cn, day} from 'utils'
import {useTranslation} from 'react-i18next';

function toDate(date) {
	return day(date).format('DD.MM.YYYY');
}

export default function SelectLicense({licenses, ...props})
{
  const {t} = useTranslation('common');
	const mappedLicenses = licenses.filter(({product}) => product).map(license => {

		const label = license.product.name
		const value = license.id

		return {
			value,
			label,
			license,
			isDisabled: license.available === 0
		}
	})

	const options = [
		{label: 'Ihre Lizenzen', options: mappedLicenses.filter(({license}) => license.isOwner)},
		{label: 'Lizenzen Ihrer Organisation', options: mappedLicenses.filter(({license}) => !license.isOwner)}
	]

	const formatOptionLabel = ({label, license}, {context}) => {
		const {order, subscription} = license

		let longLabel = label;

		if (order) {
			longLabel = t('select_license.from_order', {label, order_number: order.number});
		}
		else if (subscription) {
			longLabel = t('select_license.from_subscription', {label, subscription_number: subscription.number});
		}

		if (context === 'value') {
			return (
				<span>{longLabel}</span>
			)
		}


		const {quantity, available, validFrom, validTo, product} = license
		const {releaseDate} = product

		const isExpired = () => {
			// Publication
			if (releaseDate) {
				if (day(validFrom).isBefore(releaseDate) && (!validTo || day(validTo).isBefore(releaseDate))) {
					return 0
				}

				return 2
			}

			// Collection
			return (validTo && day(validTo).isBefore(day())) ? 1 : 2
		}


		const renderValidity = () => {
			if (!validTo) {
				return t('select_license.valid_at', {from: toDate(validFrom)});
			}

			if (day(validTo).isAfter(day().add(1, 'year'))) {
				return t('select_license.valid_from', {from: toDate(validFrom)});
			}

			return t('select_license.valid_from_to', {from: toDate(validFrom), to: toDate(validTo)});
		}

		return (
			<div>
				<div className="font-weight-bold">{label}</div>
				{order && (
					<div>
						{t('select_license.origin_order', {order_number: order.number})}
					</div>
				)}
				{subscription && (
					<div>
						{t('select_license.origin_subscription', {subscription_number: subscription.number})}
					</div>
				)}
				<div>
					{t('select_license.availability')} <span className={cn({'text-success' : available > 0, 'text-danger': available === 0})}>{available} {t('select_license.of')} {quantity}</span>
				</div>
				<div>
					{t('select_license.validity')} <span className={cn({'text-success': isExpired() === 2, 'text-warning': isExpired() === 1, 'text-danger': isExpired() === 0, })}>{renderValidity()}</span>
				</div>
			</div>
		)
	}

	return (
		<Select 
			{...props} 
			options={options}
			formatOptionLabel={formatOptionLabel}
			defaultValue={null}
		/>
	)
}
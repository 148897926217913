import React, {useEffect, useState, useRef} from 'react'
import ReactDOM from 'react-dom'
import {Modal} from 'react-bootstrap'
import Loading from 'common/Loading'
import scriptLoader from 'react-async-script-loader'

let PayPalButtons = null

function PayPalModal({isScriptLoaded, isScriptLoadSucceed, show, onHide, total, setExternalOrderNumber, deliveryAddress}) {
	const [showButtons, setShowButtons] = useState(false)
	const [loading, setLoading] = useState(true)

	const firstLoad = useRef(true)

	useEffect(() => {
		const scriptJustLoaded = firstLoad.current && !showButtons && isScriptLoaded

		if (scriptJustLoaded) {
			if (isScriptLoadSucceed) {
				firstLoad.current = false

				PayPalButtons = window.paypal.Buttons.driver('react', {
					React,
					ReactDOM
				})

				setLoading(false)
				setShowButtons(true)
			}
		}
	}, [isScriptLoaded, isScriptLoadSucceed, showButtons])

	function createOrder(data, actions) {
		const amount = Math.round(total() * 100) / 100;
		return actions.order.create({
			purchase_units: [
				{
					description: 'VdS Webshop Bestellung',
					soft_descriptor: '*VdS*Verlag',
					amount: {
						currency_code: 'EUR',
						value: amount
					},
					shipping: deliveryAddress && {
						address: {
							address_line_1: this.deliveryAddress.line1,
							address_line_2: this.deliveryAddress.line2,
							admin_area_2: this.deliveryAddress.city,
							admin_area_1: this.deliveryAddress.state,
							postal_code: this.deliveryAddress.zip,
							country_code: this.deliveryAddress.country,
						}
					}
				}
			],
			application_context: {
				brand_name: 'VdS Schadenverhütung GmbH',
				shipping_preference: deliveryAddress ? 'SET_PROVIDED_ADDRESS' : 'NO_SHIPPING',
				user_action: 'PAY_NOW'
			}
		})
	}

	function onApprove(data, actions) {
		actions.order.capture().then(details => {
			const id = details?.purchase_units[0]?.payments?.captures[0]?.id
			setExternalOrderNumber(id)
			onHide()
		})
	}

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Body>
				{loading && <Loading/>}
				{showButtons && (
					<PayPalButtons
						createOrder={createOrder}
						onApprove={onApprove}
					/>
				)}
			</Modal.Body>
		</Modal>
	)
}

export default scriptLoader(`https://www.paypal.com/sdk/js?currency=EUR&enable-funding=card,credit&disable-funding=sepa&locale=de_DE&client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`)(PayPalModal);
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link, Redirect} from 'common/Links';
import {ArrowLeft, PinboardIcon} from 'common/Icons';

import {PublicationList} from 'common/CategoryProductList';
import Keyvisual from 'common/Keyvisual';
import RequestLoginModal from 'common/RequestLoginModal';
import {useTranslation, Trans} from 'react-i18next';

import {
	useUser,
	useCategories,
	useParams,
	useQuery,
	useMutation,
	usePageTitle,
	useState,
} from 'hooks';
import {
	queryGetPublicationListByCategory,
	mutationAddCategoryToPinboard,
	mutationRemovePinFromPinboard,
} from 'graph';
import {cn} from 'utils';

import subArrowImage from '../../.vds/Public/Icons/Pfeile/Pfeil_Blau_Nach_Rechts.svg';

const BreadCrumb = ({id, slugPath, parent}) => {
	const {t} = useTranslation('category');
	const name = t(id);
	const categories = useCategories();
	return (
		<>
			{parent && <BreadCrumb {...categories.findById(parent.id)} />}
			<li className="breadcrumb-item">
				{slugPath ? (
					<Link to={`/kategorie/${slugPath}`} title={name}>
						<span>{name}</span>
					</Link>
				) : (
					<span>{name}</span>
				)}
			</li>
		</>
	);
};

const BreadCrumbs = ({category}) => {
	const {t} = useTranslation('common');
	return (
		<div className="row breadcrumb-wrapper">
			<div className="col-12">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/" title={t('navigation.home')}>
								<span>{t('navigation.home')}</span>
							</Link>
						</li>
						<BreadCrumb {...category} />
					</ol>
				</nav>
			</div>
		</div>
	);
};

function PinButtonDisabled()
{
	const {t} = useTranslation('common');
	const title = t('buttons.add_to_pinboard');
	const [showLogin, setShowLogin] = useState(false);

	function onClick(event) {
		event.preventDefault();
		setShowLogin(true);
	}

	return (
		<>
			<RequestLoginModal
				show={showLogin}
				handleClose={e => setShowLogin(false)}
			/>
			<div id="box-anchor" className="mt-2">
				<a
					onClick={onClick}
					href="#pin-to-pinboard"
					title={title}
				>
					<div className="row">
						<div className="col">
							<span>{title}</span>
						</div>
						<div className="col-auto">
							<PinboardIcon />
						</div>
					</div>
				</a>
			</div>
		</>
	);
}

function PinButtonActive({category}) {
	const {t} = useTranslation('common');
	const {t: ct} = useTranslation('category')
	const [pin, {loading: pinning}] = useMutation(
		mutationAddCategoryToPinboard,
		{
			variables: {
				input: {
					category: {
						id: category.id,
					},
				},
			},
		}
	);
	const [unpin, {loading: unpinning}] = useMutation(
		mutationRemovePinFromPinboard,
		{
			variables: {
				id: category.pin?.id,
			},
			update(cache, {data}) {
				const normalizedId = cache.identify(data.pinboardRemovePin);
				cache.evict({id: normalizedId});
				cache.gc();
			},
		}
	);

	const isPinned = !!category.pin;

	function toggle(event) {
		event.preventDefault();

		if (pinning || unpinning) {
			return;
		}

		if (isPinned) {
			unpin();
		} else {
			pin();
		}
	}

	const title = isPinned ? t('buttons.remove_from_pinboard') : t('buttons.add_to_pinboard');
	const name = ct(category.id);

	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip>
					{isPinned && (
						<Trans t={t} i18nKey="buttons.tooltip.pinned_category" name={name}>
							<h6>
								<strong>
									Kostenlosen Infodienst für diese Kategorie kündigen.
								</strong>
							</h6>
							<p className="mb-0">
								Sie erhalten keine weiteren Nachrichten zum
								Thema {{name}}.
							</p>
						</Trans>
					)}
					{!isPinned && (
						<Trans t={t} i18nKey="buttons.tooltip.unpinned_category" name={name}>
							<h6>
								<strong>
									Kostenlosen Infodienst für diese Kategorie abonnieren.
								</strong>
							</h6>
							<p className="mb-0">
								Wir benachrichtigen Sie per E-Mail, wenn neue
								Publikationen zum Thema {{name}}
								erscheinen.
							</p>
						</Trans>
					)}
				</Tooltip>
			}
		>
			<div id="box-anchor" className="mt-2">
				<a
					className={cn({'text-success': isPinned})}
					onClick={toggle}
					href={
						isPinned ? '#unpin-from-pinboard' : '#pin-to-pinboard'
					}
					title={title}
				>
					<div className="row">
						<div className="col">
							<span>{title}</span>
						</div>
						<div className="col-auto">
							<PinboardIcon />
						</div>
					</div>
				</a>
			</div>
		</OverlayTrigger>
	);
}

function PinButton({category})
{
	const user = useUser();

	if (user) {
		return (
			<PinButtonActive category={category} />
		)
	}

	return (
		<PinButtonDisabled />
	)
}

const SideSubNav = ({categories}) => {
	const {t} = useTranslation('category');
	return (
		<>
			<img className="hassubicon" src={subArrowImage} alt="arrow_right" />
			<ul className="nested nav flex-column">
				{categories.map((category) => (
					<li key={category.id} className="nav-item">
						<Link
							to={`/kategorie/${category.slugPath}`}
							title={t(category.id)}
						>
							<span>{t(category.id)}</span>
						</Link>
					</li>
				))}
			</ul>
		</>
	);
};

function SideNav({category}) {
	const {t} = useTranslation('common');
	const {t:tc} = useTranslation('category');

	const parent = useCategories().findById(
		category.parent && category.parent.id
	);

	return (
		<div className="d-none d-md-block col-md-3" style={{paddingRight: 40}}>
			{category.children.length > 0 && (
				<ul className="nav subnavigation flex-column mb-4">
					{category.children.map((category) => (
						<li key={category.id} className="nav-item hassub">
							<Link
								to={`/kategorie/${category.slugPath}`}
								title={tc(category.id)}
							>
								<span>{tc(category.id)}</span>
							</Link>
							{category.children.length > 0 && (
								<SideSubNav categories={category.children} />
							)}
						</li>
					))}
				</ul>
			)}
			{parent && (
				<div id="box-anchor">
					<Link
						to={`/kategorie/${parent.slugPath}`}
						title={tc(parent.id)}
					>
						<div className="row">
							<div className="col">
								<span>{t('buttons.level_up')}</span>
							</div>
							<div className="col-auto">
								<ArrowLeft />
							</div>
						</div>
					</Link>
				</div>
			)}
			<PinButton category={category} />
		</div>
	);
}

const COVERS = {
	Brandschutz:
		'https://vds.de/fileadmin/Website_Content_Images/Kompetenzen/Brandschutz/Brandschutz_Hauptseite/VdS-Schadenverhuetung-Brandschutz-1440x500px-Header.jpg',
	Security:
		'https://vds.de/fileadmin/Website_Content_Images/Kompetenzen/Security/Security_Hauptseite/VdS-Schadenverhuetung-Security-1440x500px-Header.jpg',
	misc: 'https://vds.de/fileadmin/Website_Content_Images/Kompetenzen/Naturgefahren/Naturgefahren_Hauptseite/VdS-Schadenverhuetung-Naturgefahren-1440x500px-Header.jpg',
};

export default function Category(props) {
	const {t} = useTranslation('common');
	const {t:tc} = useTranslation('category');
	const slugPath = useParams()[0];
	const category = useCategories().findBySlugPath(slugPath);
	const {data, loading} = useQuery(queryGetPublicationListByCategory, {
		variables: {
			category: category.id,
		},
		skip: !category,
	});

	usePageTitle(category?.name);

	if (!category) {
		return <Redirect to="/seite-nicht-gefunden" />;
	}

	const publications = data?.publicationsByCategory || [];
	const getCover = (c) =>
		COVERS[c.name] || (c.parent ? getCover(c.parent) : COVERS['misc']);

	return (
		<>
			<Keyvisual src={getCover(category)} />
			<section id="content" className="container-fluid">
				<BreadCrumbs category={category} />
				<div className="row">
					<SideNav category={category} />
					<div className="col-12 col-md-9 content">
						<header>
							<h4>{t('category.subhead')}</h4>
							<h1>{tc(category.id)}</h1>
						</header>
						<PublicationList
							publications={publications}
							loading={loading}
						/>
					</div>
				</div>
			</section>
		</>
	);
}

import React from 'react'

export default function Loading({text}) {
	return (
		<div className="loader-container">
			<span className="loader"/>
			{text && (
				<h1>{text}</h1>
			)}
		</div>
	)
}
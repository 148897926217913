import React from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {get} from 'lodash'
import cn from 'classnames'

const Textarea = ({
	id,
	name, 
	label, 
	help,
	rules,
	placeholder = ''
}) => {
	const {register, errors} = useFormContext()
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			{help && <small className="form-text text-muted">{help}</small>}
			<textarea 
				ref={register(rules)} 
				className={cn('form-control', {'is-invalid': get(errors, name)})}
				name={name}
				id={id || name} 
				placeholder={placeholder}/>
			<ErrorMessage name={name} errors={errors}>
				{({ message }) => <div className="invalid-feedback">{message}</div>}
			</ErrorMessage>
		</div>
	)	
}


export default Textarea
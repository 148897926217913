import gql from 'graphql-tag'

export const fragmentPublicationBase = gql`
	fragment PublicationBase on Publication {
		id
		number
		name
		slugName
		title
		version
		displayVersion
		releaseDate
		state
		cover {
			name
			url
		}
		orderMode
		actionUrl
		actionLicenseLevel
		material {
			number
		}
		pin {
			id
		}
	}
`

export const fragmentPublicationExtended = gql`
	fragment PublicationExtended on Publication {
		id
		number
		name
		slugName
		title
		version
		displayVersion
		releaseDate
		state
		subtitle
		description
		descriptionExtended
		cover {
			name
			url
		}
		material {
			number
		}
		pin {
			id
		}
		versions {
			id
			number
			name
			slugName
			title
			version
			displayVersion
			releaseDate
			state
		}
		nextVersion {
			id
			number
			name
			slugName
			state
		}
		prevVersion {
			id
			number
			name
			slugName
			state
		}
		pageCount
		priceGroup
		price {
			priceFormated
			price
			prices {
				amount
				priceFormated
				price
			}
		}
		orderMode
		subscriptionMode
		subscriptionPlan {
			quantity
			cycle {
				intervalUnit
				intervalCount
				discount {
					type
					value
				}
				totalCycles
			}
		}
		actionUrl
		actionLicenseLevel
		checkout {
			id
		}
		files {
			additionalFiles {
				id
				mime
				url
				name
			}
		}
		collections {
			id
			number
			name
			slugName
			title
		}
		licenses {
			id
			isOwner
			quantity
			available
			validFrom
			validTo
			order {
				number
			}
			subscription {
				number
			}
			product {
				id
				name
				slugName
				title
				... on Publication {
					releaseDate
					state
				}

			}
			checkoutsLength
		}
		bundles {
			id
			maxQuantity
			discount {
				type
				value
			}
			orderMode
			publications {
				id
				number
				name
				slugName
				title
				version
				displayVersion
				releaseDate
				state
				price {
					priceFormated
					price
					prices {
						amount
						priceFormated
						price
					}
				}
			}
		}
		withdrawnReason
		withdrawnComment
		withdrawnRelation {
			publication {
				id
				number
				name
				slugName
			}
		}
	}
`

export const fragmentCollectionBase = gql`
	fragment CollectionBase on Collection {
		id
		number
		name
		slugName
		title
	}
`

export const fragmentCollectionExtended = gql`
	fragment CollectionExtended on Collection {
		id
		number
		name
		title
		state
		subtitle
		description
		price {
			priceFormated
			price
			prices {
				amount
				priceFormated
				price
			}
		}
		checkout {
			id
		}
		material {
			number
		}
		actionUrl
		actionLicenseLevel
		orderMode
		subscriptionMode
		subscriptionPlan {
			quantity
			cycle {
				intervalUnit
				intervalCount
				discount {
					type
					value
				}
				totalCycles
			}
		}
		cover {
			name
			url
		}
		publications {
			...PublicationBase
		}
		licenses {
			id
			isOwner
			quantity
			available
			validFrom
			validTo
			order {
				number
			}
			subscription {
				number
			}
			product {
				id
				name
				slugName
				title
				... on Publication {
					releaseDate
					state
				}

			}
			checkoutsLength
		}
	}
	${fragmentPublicationBase}
`

export const fragmentAddressDetail = gql`
	fragment AddressDetail on Address {
		number
		name
		name2
		name3
		name4
		street
		city
		zip
		poBox
		poBoxZip
		countryCode
	}
`

export const fragmentBasketDetail = gql`
	fragment BasketDetail on Basket {
		id
		purchaseNumber
		customerAddress {
			...AddressDetail
		}
		recipientAddress {
			...AddressDetail
		}
		invoiceAddress {
			...AddressDetail
		}
		voucher {
			id
			code
		}
		items {
			id
			type
			quantity
			discount {
				type
				value
			}
			product {
				...PublicationExtended
				...CollectionExtended
			}
			extraDownload
			subscription

			isPublication
			isPublicationCollection
			isBundle
		}
	}
	${fragmentPublicationExtended}
	${fragmentCollectionExtended}
	${fragmentAddressDetail}
`

export const fragmentCategoryBase = gql`
	fragment CategoryBase on Category {
		id
		name
		slugName
		slugPath
		pin {
			id
		}
		parent {
			id
		}
	}
`

export const fragmentBranchBase = gql`
	fragment Branch_Base on Branch {
		id
		name
		slugName
		slugPath
		parent {
			id
		}
	}
`

export const fragmentPinBase = gql`
	fragment Pin_Base on Pin {
		id
		createdAt
		category {
			id
			name
			pin {
				id
			}
		}
		material {
			number
			product {
				id
				name
				pin {
					id
				}
			}
		}
	}
`

export const queryGetContextBasket = gql`
	query getContext {
		basket {
			...BasketDetail
		}
	}
	${fragmentBasketDetail}
`

export const queryGetContext = gql`
	query getContext {
		urls {
			login
			logout
		}
		user {
			username
			email
			userTitle
			userFirstname
			userLastname
			userExtraname
			personalAddress {
				...AddressDetail
			}
			companyAddress {
				...AddressDetail
			}
			customer {
				number
				address {
					...AddressDetail
				}
			}
			pins {
				id
			}
		}
		collections {
			...CollectionBase
		}
		basket {
			...BasketDetail
		}
		categories {
			... CategoryBase
			children {
				... CategoryBase
				children {
					... CategoryBase
					children {
						... CategoryBase
						children {
							... CategoryBase
							children {
								... CategoryBase
							}
						}
					}
				}
			}
		}
		branches {
			... Branch_Base
			children {
				... Branch_Base
				children {
					... Branch_Base
					children {
						... Branch_Base
						children {
							... Branch_Base
							children {
								... Branch_Base
							}
						}
					}
				}
			}
		}
	}

	${fragmentCollectionBase}
	${fragmentCategoryBase}
	${fragmentBranchBase}
	${fragmentBasketDetail}
	${fragmentAddressDetail}
`


export const queryGetPublicationsList = gql`
	query getPublications {
		publications {
			...PublicationBase
		}
	}
	${fragmentPublicationBase}
`

export const queryGetPublicationList = gql`
	query getPublication($id: ID!) {
		publication(id: $id) {
			...PublicationBase
		}
	}
	${fragmentPublicationBase}
`

export const queryGetPublicationDetail = gql`
	query getPublication($id: ID!){
		publication(id: $id) {
			...PublicationExtended
		}
	}
	${fragmentPublicationExtended}
`

export const queryGetCollectionDetail = gql`
	query getCollection($id: ID!){
		collection(id: $id) {
			...CollectionBase
			...CollectionExtended
		}
	}
	${fragmentCollectionBase}
	${fragmentCollectionExtended}
`

export const queryGetCategoriesList = gql`
	query getCategories {
		categories {
			id
			name
			slugName
			pin {
				id
			}
			publications {
				...PublicationBase
			}
		}
	}
	${fragmentPublicationBase}
`

export const queryGetPublicationListByCategory = gql`
	query getPublicationListByCategory($category: ID!) {
		publicationsByCategory(category: $category) {
			...PublicationBase
		}
	}

	${fragmentPublicationBase}
`

export const queryGetPublicationListByBranch = gql`
	query getPublicationListByBranch($branch: ID!) {
		publicationsByBranch(branch: $branch) {
			...PublicationBase
		}
	}

	${fragmentPublicationBase}
`

export const queryGetHome = gql`
	query getHome {
		collections {
			...CollectionBase
		}
		publications {
			...PublicationBase
		}
		recentPublicationsFireprotection {
			...PublicationBase
		}
		recentPublicationsSecurity {
			...PublicationBase
		}
	}
	${fragmentPublicationBase}
	${fragmentCollectionBase}
`

export const queryGetSearch = gql`
	query getSearch {
		collections {
			...CollectionBase
		}
		publications {
			...PublicationBase
		}
	}
	${fragmentPublicationBase}
	${fragmentCollectionBase}
`

export const fragmentOrder = gql`
	fragment OrderDetail on Order {
		number
		createdAt
		purchaseNumber
		subscription {
			number
		}
		amount
		taxes
		positions {
			id
			item
			quantity
			amount
			taxes
			download
			product {
				id
				name
				slugName
				title
			}
			material {
				number
				description
			}
		}
	}
`

export const fragmentSubscription = gql`
	fragment SubscriptionDetail on Subscription {
		number
		createdAt
		validFrom
		validTo
		purchaseNumber
		amount
		taxes
		positions {
			id
			item
			quantity
			amount
			taxes
			download
			product {
				id
				name
				slugName
				title
			}
			material {
				number
				description
			}
		}
	}
`

export const queryGetUserOrders = gql`
	query getUserOrders {
		user {
			username
			orders {
				...OrderDetail
			}
			customer {
				orders {
					...OrderDetail
				}
			}
		}
	}
	${fragmentOrder}
`

export const queryGetUserSubscriptions = gql`
	query getUserSubscriptions {
		user {
			username
			subscriptions {
				...SubscriptionDetail
			}
			customer {
				subscriptions {
					...SubscriptionDetail
				}
			}
		}
	}
	${fragmentSubscription}
`

export const queryGetUserLicenses = gql`
	query getUserLicenses {
		user {
			username
			checkouts {
				id
				createdAt
				activatedAt
				code
				actionUrl
				user {
					userFirstname
					userLastname
					email
				}
				product {
					id
					name
					slugName
					title
				}
			}
			licenses {
				id
				isOwner
				quantity
				available
				validFrom
				validTo
				product {
					id
					name
					slugName
					title
					... on Publication {
						releaseDate
						state
					}
				}
				order {
					number
				}
				subscription {
					number
				}
				material {
					number
					description
				}
				checkoutsLength
				checkouts {
					id
					createdAt
					activatedAt
					code
					user {
						userFirstname
						userLastname
						email
					}
				}
			}
		}
	}
`

export const queryBasketOrderSimulate = gql`
	query basketSimulate {
		basketSimulate {
			...BasketDetail
			amount
			taxes
			shippingAmount
			shippingTaxes
			positions {
				number
				quantity
				description
				total
				taxes
				download
				product {
					id
					name
					title
				}
			}
		}
	}
	${fragmentBasketDetail}
`

export const mutationAddToBasket = gql`
	mutation addItemToBasket($input: AddToBasketInput!) {
		basketAddItem(input: $input) {
			...BasketDetail
		}
	}
	${fragmentBasketDetail}
`

export const mutationRemoveBasketItem = gql`
	mutation removeBasketItem($id: ID!) {
		basketRemoveItem(id: $id) {
			...BasketDetail
		}
	}
	${fragmentBasketDetail}
`

export const mutationUpdateBasketAddresses = gql`
	mutation updateBasketAddresses($input: BasketAddressInput!) {
		basketAddAddress(input: $input) {
			...BasketDetail
		}
	}
	${fragmentBasketDetail}
`

export const mutationBasketOrderCreate = gql`
	mutation createOrder($input: CreateOrderInput!) {
		basketOrderCreate(input: $input) {
			orders {
				...OrderDetail
			}
			subscriptions {
				...SubscriptionDetail
			}
		}
	}
	${fragmentSubscription}
	${fragmentOrder}
`

export const mutationActivateLicense = gql`
	mutation activateLicense($input: ActivateLicenseInput!) {
		activateLicense(input: $input) {
			code
			user {
				username
			}
			product {
				id
				name
				slugName
			}
		}
	}
`

export const mutationActivateLicenseCode = gql`
	mutation activateLicense($input: ActivateLicenseCodeInput!) {
		activateLicenseCode(input: $input) {
			code
			product {
				id
				name
				slugName
			}
		}
	}
`

export const queryGetUserPinboard = gql`
	query getUserPinboard {
		user {
			pins {
				...Pin_Base
			}
		}
	}
	${fragmentPinBase}
`

export const mutationAddCategoryToPinboard = gql`
	mutation addCategoryToPinboard($input: AddToPinboardInput!) {
		pinboardAddCategory(input: $input) {
			...Pin_Base
		}
	}
	${fragmentPinBase}
`

export const mutationAddMaterialToPinboard = gql`
	mutation addMaterialToPinboard($input: AddToPinboardInput!) {
		pinboardAddMaterial(input: $input) {
			...Pin_Base
		}
	}
	${fragmentPinBase}
`

export const mutationRemovePinFromPinboard = gql`
	mutation removePinFromPinboard($id: ID!) {
		pinboardRemovePin(id: $id) {
			...Pin_Base
		}
	}
	${fragmentPinBase}
`
import React from 'react'
import {useQuery} from '@apollo/client'

import {queryGetContext} from 'graph'


const ShopContext = React.createContext({})

function flatten(categories) {
	return categories.reduce((stack, category) => (
		[...stack, category, ...flatten(category.children)]
	), [])
}

function findBy(stack, key) {
	return value => stack.find(item => item[key] === value)
}

function findById(stack) {
	return findBy(stack, 'id')
}

function findBySlugPath(stack) {
	return findBy(stack, 'slugPath')
}

function prepareData({categories, branches, publications, ...data}) {
	const categoriesFlat = flatten(categories)
	const branchesFlat = flatten(branches)
	
	const categoryFindById = findById(categoriesFlat)
	const categoryFindBySlugPath = findBySlugPath(categoriesFlat)
	const branchesFindById = findById(branchesFlat)
	const branchesFindBySlugPath = findBySlugPath(branchesFlat)


	return {
		...data,
		categories: {
			tree: categories,
			flat: categoriesFlat,
			findById: categoryFindById,
			findBySlugPath: categoryFindBySlugPath
		},
		branches: {
			tree: branches,
			flat: branchesFlat,
			findById: branchesFindById,
			findBySlugPath: branchesFindBySlugPath
		}
	}
}

export const ShopProvider = ({children}) => {
	//const client = useApolloClient()
	const {loading, data} = useQuery(queryGetContext);
	const shop = loading || !data ? null : prepareData(data)
	return (
		<ShopContext.Provider value={shop}>{children}</ShopContext.Provider>
	)
}
export const ShopConsumer = ShopContext.Consumer
export default ShopContext
import React from 'react'
import {sample} from 'lodash'

const BASE = 'https://vds.de/fileadmin/Website_Content_Images/Kompetenzen'
const SOURCES = [
	`${BASE}/Naturgefahren/Naturgefahren_Hauptseite/VdS-Schadenverhuetung-Naturgefahren-1440x500px-Header.jpg`,
	`${BASE}/Brandschutz/Brandschutz_Hauptseite/VdS-Schadenverhuetung-Brandschutz-1440x500px-Header.jpg`,
	`${BASE}/Security/Security_Hauptseite/VdS-Schadenverhuetung-Security-1440x500px-Header.jpg`
]

export default function Keyvisual({src, title, alt=''}) {
	return (
		<section id="abovecontent_subpages" className="container-fluid">
			<div className="row keyvisual-wrapper">
				<div className="col-12">
					<img className="img-fluid" src={src || sample(SOURCES)} alt={alt} width="1440" height="500"/>
				</div>
			</div>
		</section>
	)
}
import React from 'react'
import {Order, Subscription} from 'pages/Account'
import {useTranslation, Trans} from 'react-i18next';

function Orders({orders})
{
	const {t} = useTranslation('basket');

	if (orders.length === 0) {
		return null
	}

	return (
		<>
			<h2>{t('complete.orders.title', {count: orders.length})}</h2>
			{orders.map(order => (
				<Order
					key={order.number}
					order={order}
				/>
			))}
		</>
	)
}

function Subscriptions({subscriptions})
{
	const {t} = useTranslation('basket');

	if (subscriptions.length === 0) {
		return null
	}

	return (
		<>
			<h2>{t('complete.subscriptions.title', {count: subscriptions.length})}</h2>
			{subscriptions.map(subscription => (
				<Subscription
					key={subscription.number}
					subscription={subscription}
				/>
			))}
		</>
	)
}

export default function Completed({data={}})
{
	const {t} = useTranslation('basket');

	return (
		<>
			<Trans t={t} i18nKey="complete.header">
				<h2>Bestellung abgeschlossen</h2>
				<p className="lead">Vielen Dank für Ihre Bestellung. In Kürze erhalten Sie von uns eine Eingangsbestätigung der Bestellung per E-Mail.</p>
			</Trans>
			<Orders 
				orders={data.orders || []}
			/>
			<Subscriptions
				subscriptions={data.subscriptions || []}
			/>
			<Trans t={t} i18nKey="complete.footer">
				<p className="lead">Wenn Sie Fragen zu Ihrer Bestellung haben, wenden Sie sich bitte an unsere Hotline <a href="callto:+492217766122">+49 (0)221 77 66 122</a> oder per E-Mail an <a href="mailto:verlag@vds.de">verlag@vds.de</a>.</p>
			</Trans>
		</>
	)
}